@import url("//maxcdn.bootstrapcdn.com/font-awesome/4.1.0/css/font-awesome.min.css");
/**
 * ----------------------------------------------------------------------------------------------
 * This is a variation of Normalize.css (http://necolas.github.io/normalize.css/)
 * ----------------------------------------------------------------------------------------------
 */
/**
 * Base
 */
*,
*:before,
*:after {
  box-sizing: border-box !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 13px;
  /*Edited by Irfan Mumtaz */ }

html {
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -ms-overflow-style: -ms-autohiding-scrollbar; }

body {
  margin: 0;
  overflow-x: hidden; }

/**
 * HTML5 display definitions
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Text-level semantic
 */
:focus,
:active {
  outline: none; }

a {
  color: inherit;
  background-color: transparent;
  text-decoration: none; }
  a:active, a:hover {
    outline: 0; }

b,
strong {
  font-weight: bold; }

small {
  font-size: 80%; }

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  font-size: inherit; }

p:last-child,
h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child {
  margin-bottom: 0; }

/**
 * Embedded content
 */
img {
  max-width: 100%;
  height: auto;
  border: 0;
  vertical-align: baseline; }

svg:not(:root) {
  overflow: hidden; }

/**
 * Grouping content
 */
ul,
ol {
  margin: 0;
  padding: 0;
  list-style-position: inside; }

pre {
  overflow: auto; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 16px;
  font-size: 1rem; }

/**
 * Forms
 */
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  -webkit-appearance: none;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  margin: 0 0.125rem;
  padding: 6px 10px 12px;
  padding: 0.375rem 0.625rem 0.75rem; }

legend {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

/**
 * Tables
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

.slick-slider {
  display: block;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer; }

.slick-track,
.slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  display: block;
  position: relative;
  top: 0;
  left: 0; }
  .slick-track:before, .slick-track:after {
    display: table;
    content: ''; }
  .slick-track:after {
    clear: both; }

.slick-loading .slick-track {
  visibility: hidden; }

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
  overflow: hidden; }

[dir='rtl'] .slick-slide {
  float: right; }

.slick-slide img {
  display: block; }

.slick-slide.slick-loading img {
  display: none; }

.slick-slide.dragging img {
  pointer-events: none; }

.slick-initialized .slick-slide {
  display: block !important; }

.slick-loading .slick-slide {
  visibility: hidden; }

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.clearfix::after {
  content: '';
  display: table;
  clear: both; }

/**
 * COLORS
 */
/**
 * TYPOGRAPHY
 */
/**
 * HEADER
 */
/**
 * SLIDESHOW
 */
/**
 * HOME PAGE
 */
/**
 * LIST OF COLLECTIONS
 */
/**
 * COLLECTION
 */
/**
 * PRODUCT
 */
/**
 * BLOG
 */
/**
 * Sticky footer, normalized to work on all browsers (http://philipwalton.com/articles/normalizing-cross-browser-flexbox-bugs/)
 */
.page__container {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100vh;
  height: 100%;
  background: #fdfdfd; }

.top-bar,
.header__mobile-nav,
.header,
.footer {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0; }

.main {
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  margin-top: 25px;
  margin-top: 1.5625rem; }

.container {
  /*max-width: 1200px;*/
  max-width: 1263px;
  margin: 0 auto;
  padding: 0 20px;
  padding: 0 1.25rem; }

.container--shrink {
  max-width: 680px; }

.inner {
  margin: 0 auto;
  /*  padding: 0 20px;
    padding: 0 1.25rem; */ }

.main > .container > .inner {
  padding: 0; }

.page__overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #000000;
  opacity: 0;
  z-index: 5;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0s 0.3s; }

.page__overlay--open {
  opacity: 0.6;
  visibility: visible;
  transition: opacity 0.3s; }

@media (min-width: 48em) {
  .main > .container > .inner {
    padding: 0 20px;
    padding: 0 1.25rem; }

  #filters {
    width: 80%;
    margin: auto; } }
body {
  font-size: 100%;
  font-weight: 400;
  font-family: Open Sans;
  color: black;
  line-height: 1.6; }

a {
  transition: all 0.25s ease-in-out; }

.link--primary, .rte a {
  color: #1790d3; }
  .link--primary:hover, .rte a:hover {
    color: #0f618e; }

.icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  fill: currentColor; }

/**
 * Those next two rules allow to remove margin-top, this is easier to maintain to only act on the margin-bottom
 */
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  font-weight: inherit; }

p:last-child,
h1:last-child,
h2:last-child,
h3:last-child,
h4:last-child,
h5:last-child,
h6:last-child {
  margin-bottom: 0; }

.list--unstyled {
  list-style: none;
  padding: 0;
  margin: 0; }

.rte h1,
.rte h2,
.rte h3,
.rte h4,
.rte h5,
.rte h6 {
  color: black;
  font-family: Open Sans; }
.rte img {
  /*  margin-bottom: 35px;
    margin-bottom: 2.1875rem; */ }
.rte blockquote {
  margin: 30px 0;
  margin: 1.875rem 0;
  padding: 35px;
  padding: 2.1875rem;
  background: black;
  color: #fdfdfd;
  font-weight: bolder; }
.rte ul,
.rte ol {
  margin: 25px 0 25px 25px;
  margin: 1.5625rem 0 1.5625rem 1.5625rem; }
.rte h1 {
  font-size: 28px;
  font-size: 1.75rem; }
.rte h2 {
  font-size: 24px;
  font-size: 1.5rem; }
.rte h3 {
  margin-bottom: 16px;
  margin-bottom: 1rem;
  font-size: 20px;
  font-size: 1.25rem; }
.rte h4 {
  margin-bottom: 14px;
  margin-bottom: 0.875rem;
  font-size: 18px;
  font-size: 1.125rem; }
.rte h5, .rte h6 {
  margin-bottom: 12px;
  margin-bottom: 0.75rem;
  font-size: 16px;
  font-size: 1rem; }

/**
 * Those two breakpoints allow to reduce the font-size of all texts as well as the margin and
 * padding, thanks to the magic of REM
 */
html {
  font-size: 15px; }

@media (min-width: 48em) {
  html {
    font-size: 15px; }

  .cart-items {
    float: left;
    width: 60%; } }
@media (min-width: 75em) {
  html {
    font-size: 16px; }

  .cart-items {
    float: left;
    width: 60%; } }
@font-face {
  font-family: 'icomoon';
  src: url("//serbags.myshopify.com/cdn/shop/t/23/assets/icomoon.eot?v=98471704946096211631461213944");
  src: url("//serbags.myshopify.com/cdn/shop/t/23/assets/icomoon.eot?%23iefix&v=98471704946096211631461213944") format("embedded-opentype"), url("//serbags.myshopify.com/cdn/shop/t/23/assets/icomoon.woff2?v=181599731385350266261461213945") format("woff2"), url("//serbags.myshopify.com/cdn/shop/t/23/assets/icomoon.ttf?v=100789338085895404611461213944") format("truetype"), url("//serbags.myshopify.com/cdn/shop/t/23/assets/icomoon.woff?v=101332882449686064001461213945") format("woff");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-tick:before {
  content: '\e900'; }

.breadcrumb {
  display: none; }

@media (min-width: 48em) {
  .breadcrumb {
    display: inline-block;
    margin-bottom: 14px;
    margin-bottom: 0.875rem;
    vertical-align: top;
    text-transform: uppercase;
    font-size: 12px;
    font-size: 0.75rem; }

  .breadcrumb__item {
    display: inline-block; }

  .breadcrumb__item:not(.breadcrumb__item--active) {
    color: rgba(0, 0, 0, 0.4); }
    .breadcrumb__item:not(.breadcrumb__item--active):hover {
      color: black; }
    .breadcrumb__item:not(.breadcrumb__item--active)::after {
      display: inline-block;
      content: '/';
      margin: 0 3px;
      margin: 0 0.1875rem;
      transition: color 0.25s ease-in-out; } }
.alert {
  margin-bottom: 30px;
  margin-bottom: 1.875rem;
  padding: 15px 20px 20px 20px;
  padding: 0.9375rem 1.25rem 1.25rem 1.25rem;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  box-shadow: 1px 1px 2px #e4e4e4; }

.alert__title {
  position: relative;
  display: block;
  font-size: 20px;
  font-size: 1.25rem; }
  .alert__title::before {
    position: absolute;
    content: '';
    width: 3px;
    width: 0.1875rem;
    height: 100%;
    left: -20px;
    left: -1.25rem;
    background: transparent; }

.alert__errors {
  margin-top: 20px;
  margin-top: 1.25rem; }

.alert--mono {
  margin-bottom: 15px;
  margin-bottom: 0.9375rem;
  padding: 10px 15px;
  padding: 0.625rem 0.9375rem;
  border-radius: 3px;
  border: none;
  box-shadow: none;
  text-align: left; }
  .alert--mono .alert__title::before {
    display: none; }

.alert--error .alert__title {
  color: #e9573f; }
  .alert--error .alert__title::before {
    background: #e9573f; }

.alert--mono.alert--error {
  background: #e9573f; }
  .alert--mono.alert--error, .alert--mono.alert--error .alert__title {
    color: #ffffff; }

.alert--success .alert__title {
  color: #0bb73a; }
  .alert--success .alert__title::before {
    background: #0bb73a; }

.alert--mono.alert--success {
  background: #0bb73a; }
  .alert--mono.alert--success,
  .alert--mono.alert--success .alert__title {
    color: #ffffff; }

@media (min-width: 60em) {
  .alert__title {
    font-size: 24px;
    font-size: 1.5rem; } }
input,
textarea {
  width: 100%;
  padding: 12px 15px;
  padding: 0.75rem 0.9375rem;
  border-radius: 2px;
  border: 1px solid #e4e4e4;
  font-size: inherit;
  -webkit-appearance: none;
  /* Remove the inner box-shadow in iOS devices */ }
  input:focus,
  textarea:focus {
    border-color: #1790d3; }
  input::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    color: inherit; }
  input::-moz-placeholder,
  textarea::-moz-placeholder {
    color: inherit; }
  input:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
    color: inherit; }
  input::placeholder,
  textarea::placeholder {
    color: inherit; }

input[type="checkbox"] {
  width: 1em;
  height: 1em;
  margin-right: 15px;
  margin-right: 0.9375rem;
  -webkit-appearance: checkbox; }

textarea {
  display: block;
  /* Fix in inconsistency with Chrome/Safari */
  resize: vertical; }

input:focus,
textarea:focus {
  outline: none; }

.form--shrink {
  max-width: 500px;
  margin: 0 auto; }

.form__control,
.form__row {
  position: relative;
  margin-bottom: 30px;
  margin-bottom: 1.875rem;
  text-align: left; }
  .form__control:last-child,
  .form__row:last-child {
    margin-bottom: 0; }

.form__label, .spr-form-label {
  display: block;
  margin-bottom: 10px;
  margin-bottom: 0.625rem;
  font-size: 14px;
  font-size: 0.835rem;
  font-weight: bolder; }

.form--inline .form__control {
  display: table;
  width: 100%;
  table-layout: fixed;
  margin-bottom: 20px;
  margin-bottom: 1.25rem; }
.form--inline .form__label, .form--inline .spr-form-label {
  display: table-cell;
  margin: 0 10px 0 0;
  margin: 0 0.625rem 0 0;
  vertical-align: middle; }
  .form--inline .form__label + input, .form--inline .spr-form-label + input, .form--inline .form__label + textarea, .form--inline .spr-form-label + textarea, .form--inline .form__label + .form__select, .form--inline .spr-form-label + .form__select {
    display: table-cell;
    width: 55%; }

.form__submit-duo input:last-child {
  margin-top: 10px;
  margin-top: 0.625rem; }

.flexbox .form__submit-duo {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }
  .flexbox .form__submit-duo input {
    margin: 0 15px 0 0;
    margin: 0 0.9375rem 0 0;
    -webkit-flex: 2;
    -ms-flex: 2;
    flex: 2; }
  .flexbox .form__submit-duo input[type="submit"] {
    margin: 0;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1; }

@media (min-width: 48em) {
  .flexbox .form__row {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; }
    .flexbox .form__row .form__control {
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
      margin: 0 20px 0 0;
      margin: 0 1.25rem 0 0; }
    .flexbox .form__row .form__control:last-child {
      margin-right: 0; } }
/**
 * Style selects
 */
select {
  /* Disable built-in styles */
  display: inline-block;
  width: 130px;
  height: 30px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 0px;
  font-size: 14px;
  font-size: 0.875rem;
  padding-top: 0px;
  padding-bottom: 0px;
  cursor: pointer;
  line-height: normal;
  background: transparent;
  border: 1px solid #e4e4e4;
  /* Remove the ugly blue background on IE when a value is selected */ }
  select:active, select:focus {
    outline: none; }
  select:focus::-ms-value {
    background: inherit;
    color: inherit; }
  .ie9 select {
    padding-right: 0;
    /* Allow to cover the arrow */ }

.cart-item__quantity {
  background: rgba(221, 221, 221, 0.39);
  color: #000;
  padding-left: 10px;
  padding-right: 0px; }

/* Allow to remove the ugly dashes when selecting an option in Firefox */
select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 black; }

/* Disable default styles for IE10+ */
select::-ms-expand {
  display: none; }

/* Browsers do not support ::after pseudo-element on <select> elements, so we are forced
   to wrap the <select> around an additional div */
.form__select {
  position: relative;
  border: none; }
  .form__select .icon {
    position: absolute;
    top: calc(50% - 8px);
    top: calc(50% - 0.5rem);
    right: 15px;
    right: 0.9375rem;
    width: 18px;
    width: 1.125rem;
    height: 18px;
    height: 1.125rem;
    line-height: normal;
    pointer-events: none;
    fill: currentColor; }
  .ie9 .form__select svg {
    display: none; }

.button, .spr-summary-actions-newreview,
.spr-button-primary {
  position: relative;
  display: inline-block;
  padding: 15px 40px;
  padding: 0.9375rem 2.5rem;
  line-height: normal;
  border: none;
  border-radius: 2px;
  text-transform: uppercase;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bolder;
  transition: all 0.25s ease-in-out;
  cursor: pointer; }
  .button[disabled], [disabled].spr-summary-actions-newreview,
  [disabled].spr-button-primary {
    opacity: 0.4;
    cursor: not-allowed; }

.button--full {
  width: 100%; }

.button--small {
  padding: 12px 25px;
  padding: 0.75rem 1.5625rem; }

.button--primary, .spr-summary-actions-newreview,
.spr-button-primary {
  color: white;
  background: #1790d3; }
  .button--primary:hover, .spr-summary-actions-newreview:hover,
  .spr-button-primary:hover {
    background: #4bb2eb; }

.button--secondary {
  color: white;
  background: #3f3f3f; }
  .button--secondary:hover {
    background: #656565; }

.button-wrapper {
  text-align: center; }

.b-modal {
  cursor: default !important; }

.modal {
  position: relative;
  width: 90%;
  max-width: 600px;
  padding: 15px 20px;
  padding: 0.9375rem 1.25rem;
  border-radius: 4px;
  background-size: cover;
  background-color: #fdfdfd;
  text-align: center;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3); }

.modal__close {
  position: absolute;
  right: 15px;
  right: 0.9375rem;
  top: 15px;
  top: 0.9375rem; }
  .modal__close svg {
    width: 25px;
    width: 1.5625rem;
    height: 25px;
    height: 1.5625rem; }

.modal__title {
  margin: 0 0 30px 0;
  margin: 0 0 1.875rem 0;
  font-size: 24px;
  font-size: 1.5rem;
  font-weight: bolder;
  color: black; }

.modal__newsletter {
  margin: 50px auto 0 auto;
  margin: 3.125rem auto 0 auto;
  max-width: 420px;
  color: black; }
  .modal__newsletter input[type="email"] {
    margin-bottom: 15px;
    margin-bottom: 0.9375rem; }

/**
 * Special code for the marketing popup
 */
.marketing-popup,
.marketing-popup .modal__title {
  color: #919191; }

@media (min-width: 48em) {
  .modal {
    padding: 45px 50px;
    padding: 2.8125rem 3.125rem; }

  .modal__title {
    font-size: 30px;
    font-size: 1.875rem; }

  .flexbox .modal__newsletter .modal__form {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; }
    .flexbox .modal__newsletter .modal__form input[type="email"] {
      margin: 0 15px 0 0;
      margin: 0 0.9375rem 0 0;
      -webkit-flex: 2;
      -ms-flex: 2;
      flex: 2; }
    .flexbox .modal__newsletter .modal__form input[type="submit"] {
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1; } }
@media (min-width: 60em) {
  .modal {
    width: 600px;
    width: 37.5rem; } }
.hamburger {
  display: block;
  position: absolute;
  overflow: hidden;
  top: 50%;
  left: 50%;
  margin: 0 auto;
  padding: 0;
  width: 28px;
  width: 1.75rem;
  height: 35px;
  height: 2.1875rem;
  font-size: 0;
  text-indent: -9999px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  cursor: pointer;
  z-index: 3;
  background: none;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-tap-highlight-color: transparent; }
  .hamburger:focus {
    outline: none; }
  .hamburger span {
    display: block;
    position: absolute;
    top: 16px;
    top: 1rem;
    left: 0;
    right: 0;
    height: 2px;
    height: 0.125rem;
    background: black;
    transition: background 0s 0.2s; }
  .hamburger span::before,
  .hamburger span::after {
    position: absolute;
    content: '';
    display: block;
    left: 0;
    width: 100%;
    height: 2px;
    height: 0.125rem;
    background-color: black;
    transition-duration: 0.2s, 0.2s;
    transition-delay: 0.2s, 0s; }
  .hamburger span::before {
    top: -9px;
    top: -0.5625rem;
    transition-property: top, -webkit-transform;
    transition-property: top, transform; }
  .hamburger span::after {
    bottom: -9px;
    bottom: -0.5625rem;
    transition-property: bottom, -webkit-transform;
    transition-property: bottom, transform; }

.header__mobile-tab--open .hamburger span {
  background: none; }
.header__mobile-tab--open .hamburger span::before,
.header__mobile-tab--open .hamburger span::after {
  transition-delay: 0s, 0.2s;
  background: white; }
.header__mobile-tab--open .hamburger span::before {
  top: 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg); }
.header__mobile-tab--open .hamburger span::after {
  bottom: 0;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg); }

@-webkit-keyframes spinner-bounce {
  0%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0); }

  50% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes spinner-bounce {
  0%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0); }

  50% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

.spinner {
  position: relative;
  width: 40px;
  width: 2.5rem;
  height: 40px;
  height: 2.5rem;
  margin: 25px auto;
  margin: 1.5625rem auto; }

.spinner__bounce1, .spinner__bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: black;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: spinner-bounce 2.0s infinite ease-in-out;
  animation: spinner-bounce 2.0s infinite ease-in-out; }

.spinner__bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s; }

@media (min-width: 48em) {
  .spinner {
    margin: 40px auto;
    margin: 2.5rem auto; } }
table {
  width: 100%;
  border: 1px solid #e4e4e4;
  border-spacing: 0;
  border-collapse: separate;
  border-radius: 4px;
  font-size: 15px;
  font-size: 0.9375rem;
  box-shadow: 0 1px 1px rgba(228, 228, 228, 0.6); }

thead {
  text-align: left;
  text-transform: uppercase;
  font-size: 13px;
  font-size: 0.8125rem;
  color: black; }
  thead th {
    padding: 10px 15px;
    padding: 0.625rem 0.9375rem;
    border-bottom: 1px solid #e4e4e4;
    /*color: $background;*/
    color: #000;
    background: black; }
    thead th:first-child {
      border-top-left-radius: 4px; }
    thead th:last-child {
      border-top-right-radius: 4px; }

tbody td {
  padding: 18px 0;
  padding: 1.125rem 0;
  border-bottom: 1px solid #e4e4e4; }
tbody tr:last-child td {
  border-bottom: none; }

tfoot td {
  padding: 10px 15px;
  padding: 0.625rem 0.9375rem;
  color: #fdfdfd;
  background: black; }
tfoot tr:first-child td {
  border-top: 1px solid #e4e4e4; }
tfoot tr:last-child td:first-child {
  border-bottom-left-radius: 4px; }
tfoot tr:last-child td:last-child {
  border-bottom-right-radius: 4px; }

@media (max-width: 47.9em) {
  .table--responsive {
    border: none;
    box-shadow: none; }
    .table--responsive thead,
    .table--responsive td:not([data-label]) {
      display: none; }
    .table--responsive .sm-1 {
      width: 100%; }
    .table--responsive .cart-check {
      clear: both;
      margin-top: 15px; }
    .table--responsive tr {
      display: block;
      margin-bottom: 15px;
      margin-bottom: 0.9375rem;
      border: 1px solid #e4e4e4;
      border-bottom: none; }
      .table--responsive tr:last-child {
        margin-bottom: 0; }
        .table--responsive tr:last-child td {
          border-bottom: 1px solid #e4e4e4; }
    .table--responsive tr,
    .table--responsive td {
      float: left;
      clear: both;
      width: 100%; }
    .table--responsive th,
    .table--responsive td {
      display: block;
      text-align: right !important;
      padding: 8px 15px;
      padding: 0.5rem 0.9375rem;
      border-right: none; }
    .table--responsive td:before {
      content: attr(data-label);
      float: left !important;
      font-size: 13px;
      font-size: 0.8125rem;
      padding-right: 10px;
      padding-right: 0.625rem;
      text-transform: uppercase; }
    .table--responsive tfoot tr {
      margin-bottom: 0; }
    .table--responsive tfoot tr:first-child {
      margin-top: 25px;
      margin-top: 1.5625rem; } }

.table__right {
  text-align: right !important; }

.table__center {
  text-align: center !important; }

.table__left {
  text-align: left !important; }

.label {
  display: inline-block;
  padding: 6px 14px 4px 14px;
  padding: 0.375rem 0.875rem 0.25rem 0.875rem;
  font-size: 12px;
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: bolder; }

.label--unavailable,
.label--sold-out {
  color: white;
  background: #e95842; }

.label--on-sale {
  color: white;
  background: #e95842; }

.label--custom {
  color: white;
  background: #1790d3; }

.top-bar {
  display: none;
  /* Not visible on mobile */ }

@media (min-width: 48em) {
  .md-2 {
    width: 50%;
    float: left; }

  .top-bar {
    display: block;
    background: #000002;
    color: white;
    font-size: 13px;
    font-size: 0.8125rem;
    font-weight: bolder;
    text-transform: uppercase; }

  .top-bar__container {
    padding-top: 8px;
    padding-top: 0.5rem;
    padding-bottom: 7px;
    padding-bottom: 0.4375rem; }

  .top-bar__contact {
    display: none; } }
@media (min-width: 60em) {
  .top-bar__contact {
    display: inline-block;
    float: right; }

  .top-bar__contact-item {
    display: inline-block; }
    .top-bar__contact-item + .top-bar__contact-item::before {
      display: inline-block;
      content: '|';
      padding: 0 10px;
      padding: 0 0.625rem;
      color: white; }

  .top-bar__social .social__item {
    display: inline-block;
    vertical-align: text-top; }
    .top-bar__social .social__item:not(:last-child) {
      margin-right: 4px;
      margin-right: 0.25rem; }
  .top-bar__social svg {
    height: 14px;
    height: 0.875rem;
    width: 14px;
    width: 0.875rem; }

  .top-bar__contact-item--faded,
  .top-bar__social a {
    color: rgba(255, 255, 255, 0.6); }

  a.top-bar__contact-item--faded:hover,
  .top-bar__social a:hover {
    color: white; } }
.pagination {
  margin-top: 35px;
  margin-top: 2.1875rem;
  width: 100%;
  clear: both;
  text-align: center;
  text-transform: uppercase;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bolder; }

.pagination__prev,
.pagination__next {
  display: inline-block;
  padding: 20px 0;
  padding: 1.25rem 0; }
  .pagination__prev svg,
  .pagination__next svg {
    vertical-align: middle; }

.pagination__prev {
  float: left;
  margin-right: 25px;
  margin-right: 1.5625rem;
  padding-right: 32px;
  padding-right: 2rem; }
  .pagination__prev svg {
    margin-right: 5px;
    margin-right: 0.3125rem; }

.pagination__next {
  float: right;
  margin-left: 25px;
  margin-left: 1.5625rem;
  padding-left: 32px;
  padding-left: 2rem; }
  .pagination__next svg {
    margin-left: 5px;
    margin-left: 0.3125rem; }

.pagination__list {
  display: none; }

@media (min-width: 48em) {
  .pagination__prev,
  .pagination__next {
    float: none; }

  .pagination__prev {
    border-right: 1px solid rgba(228, 228, 228, 0.5); }

  .pagination__next {
    border-left: 1px solid rgba(228, 228, 228, 0.5); }

  .pagination__list,
  .pagination__item {
    display: inline-block;
    padding: 20px 0;
    padding: 1.25rem 0; }

  .pagination__item {
    margin: 0 18px;
    margin: 0 1.125rem; }

  .pagination__item--active {
    color: black; } }
@media (min-width: 60em) {
  .pagination__item {
    margin: 0 25px;
    margin: 0 1.5625rem; } }
.video__wrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  padding-top: 1.5625rem;
  height: 0; }

.video__element {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.swatch__colors {
  text-align: right; }

.swatch__item {
  position: relative;
  display: inline-block;
  margin-right: 5px;
  margin-right: 0.3125rem;
  padding: 5px;
  padding: 0.3125rem;
  border: 1px solid #e4e4e4;
  border-radius: 100%;
  cursor: pointer; }
  .swatch__item:last-child {
    margin-right: 0; }

.swatch__item--active {
  border-color: #1790d3; }
  .swatch__item--active::after {
    position: absolute;
    content: '\e900';
    top: -10px;
    top: -0.625rem;
    right: -6px;
    right: -0.375rem;
    width: 18px;
    width: 1.125rem;
    height: 18px;
    height: 1.125rem;
    font-family: 'icomoon';
    line-height: 18px;
    color: #ffffff;
    background: #1790d3;
    border-radius: 10px;
    font-size: 7px;
    font-size: 0.4375rem;
    text-align: center; }

.swatch__input {
  display: none; }

.swatch__label {
  height: 25px;
  height: 1.5625rem;
  width: 25px;
  width: 1.5625rem;
  margin: 0 !important;
  border-radius: 100%;
  pointer-events: none; }

.flexbox .swatch__colors {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin: -5px -5px -5px 0;
  margin: -0.3125rem -0.3125rem -0.3125rem 0;
  float: left; }
.flexbox .swatch__item {
  margin: 5px;
  margin: 0.3125rem; }

#shopify-product-reviews {
  overflow: visible !important; }

.spr-container {
  border: none !important;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 !important; }

.spr-header-title {
  display: none; }

.spr-summary {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }

.spr-summary-starrating {
  -webkit-order: 2;
  -ms-flex-order: 2;
  order: 2;
  margin: 0 0 20px 0 !important;
  margin: 0 0 1.25rem 0 !important; }

.spr-summary-caption {
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
  margin-bottom: 10px;
  margin-bottom: 0.625rem;
  color: black;
  font-weight: bolder;
  font-size: 20px;
  font-size: 1.25rem; }

.spr-summary-actions {
  -webkit-order: 3;
  -ms-flex-order: 3;
  order: 3; }

.spr-icon-star,
.spr-icon-star-half-alt {
  color: #f4da4b; }

.spr-icon-star-empty {
  color: black;
  opacity: 1 !important; }

.spr-form-title {
  display: none; }

.spr-form-contact-name,
.spr-form-contact-email,
.spr-form-review-rating,
.spr-form-review-title,
.spr-form-review-body {
  margin-bottom: 30px !important;
  margin-bottom: 1.875rem !important; }

.spr-form-actions {
  text-align: center; }
  .spr-form-actions .spr-button {
    float: none; }

.spr-form-input-error {
  border-color: #e9573f !important; }

.spr-form-input-error .spr-icon {
  color: #e9573f !important; }

.spr-form-message {
  margin-bottom: 30px !important;
  margin-bottom: 1.875rem !important;
  padding: 10px 15px !important;
  padding: 0.625rem 0.9375rem !important;
  border-radius: 3px !important;
  box-shadow: none !important; }

.spr-form-message-error {
  color: #ffffff !important;
  background: #e9573f !important; }

.spr-form-message-success {
  color: #ffffff !important;
  background: #0bb73a !important; }

.spr-review {
  position: relative;
  margin-top: 0 !important;
  margin-bottom: 45px;
  margin-bottom: 2.8125rem;
  padding: 25px !important;
  padding: 1.5625rem !important;
  border: 1px solid #e4e4e4 !important;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(228, 228, 228, 0.6); }

.spr-review-header-starratings {
  float: right; }

.spr-review-header-title {
  color: black;
  font-weight: bolder;
  font-size: 18px;
  font-size: 1.125rem; }

.spr-review-header-byline {
  margin-bottom: 20px !important;
  margin-bottom: 1.25rem !important;
  text-transform: uppercase;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bolder;
  font-style: normal !important;
  opacity: 1 !important; }

.spr-review-content {
  margin-bottom: 0 !important; }

.spr-review-footer {
  position: absolute;
  right: 0;
  bottom: -25px;
  bottom: -1.5625rem; }

.spr-pagination {
  margin-top: 55px;
  margin-top: 3.4375rem;
  clear: both;
  -webkit-flex-basis: 100%;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%; }

@media (min-width: 48em) {
  .spr-container {
    padding-top: 10px !important;
    padding-top: 0.625rem !important; }

  .spr-summary {
    display: block; }

  .spr-summary-starrating {
    display: inline-block;
    margin: 2px 0 0 0 !important;
    margin: 0.125rem 0 0 0 !important; }

  .spr-summary-caption {
    float: left;
    margin: 0 20px 0 0;
    margin: 0 1.25rem 0 0;
    color: black;
    font-weight: bolder;
    font-size: 20px;
    font-size: 1.25rem; }

  .spr-summary-actions-newreview {
    margin-top: -7px;
    margin-top: -0.4375rem;
    /*background-color: #a2a2a2;*/
    background: url(https://cdn.shopify.com/s/files/1/1203/9120/files/reviewbtnBG.png?3075524084569922352) no-repeat;
    padding: 16px 17px 15px 45px; }

  .spr-summary-actions-newreview:hover {
    background: url(https://cdn.shopify.com/s/files/1/1203/9120/files/reviewbtnBG.png?3075524084569922352) no-repeat;
    padding: 16px 17px 15px 45px; }

  .spr-reviews {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: 45px !important;
    margin-top: 2.8125rem !important; }
    .spr-reviews::after {
      content: '';
      display: table;
      clear: both; }

  .spr-review {
    /*@include gallery(2, 35px);*/
    width: calc(100% + 0.5px);
    margin-bottom: 65px;
    margin-bottom: 4.0625rem;
    box-shadow: 0 10px 45px 5px rgba(228, 228, 228, 0.6); }
    .spr-review:nth-child(2n+1) {
      clear: both;
      margin-left: 0; } }
/**
 * Navigation
 */
.menu--desktop {
  display: none; }

.menu--mobile .menu__item {
  position: relative;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5); }
  .menu--mobile .menu__item:last-child {
    border-bottom: none; }
.menu--mobile .menswearev {
  display: none; }
.menu--mobile .menu__link {
  position: relative;
  display: block;
  padding: 13px 20px 11px 20px;
  padding: 0.8125rem 1.25rem 0.6875rem 1.25rem;
  text-align: left;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: lighter;
  text-transform: uppercase;
  transition: none; }
.menu--mobile .icon-plus,
.menu--mobile .icon-minus {
  margin: 0 auto;
  width: 30px;
  width: 1.875rem;
  height: 100%; }
.menu--mobile .icon-minus {
  display: none; }
.menu--mobile .menu__icon-container {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 60px;
  width: 3.75rem;
  text-align: center; }
.menu--mobile .menu__item--open > .menu__link .icon-plus {
  display: none; }
.menu--mobile .menu__item--open > .menu__link .icon-minus {
  display: block; }
.menu--mobile .menu__links--level-1 {
  background: white;
  color: black; }
  .menu--mobile .menu__links--level-1 .menu__item {
    border-bottom-color: black; }
  .menu--mobile .menu__links--level-1 .menu__link {
    padding-left: 35px;
    padding-left: 2.1875rem; }
.menu--mobile .menu__links--level-2 {
  background: black;
  color: white; }
  .menu--mobile .menu__links--level-2 .menu__item {
    border-bottom-color: white; }
  .menu--mobile .menu__links--level-2 .menu__link {
    padding-left: 45px;
    padding-left: 2.8125rem; }

.menu__links--nested {
  display: none; }

@media (min-width: 48em) {
  .menu--desktop {
    display: block;
    margin: 30px 0 0 0;
    margin: 1.875rem 0 0 0;
    clear: both;
    font-size: 18px;
    font-size: 1.125rem;
    text-align: center;
    /*
        .menu__item:hover .menu__link,
        .menu__item--active .menu__link {
          opacity: 1;
          color:#cc0000;
        }
    */ }
    .menu--desktop .menu__item {
      position: relative;
      display: inline-block;
      margin-right: 15px;
      margin-right: 0.9375rem;
      text-transform: uppercase;
      font-weight: bolder;
      transition: opacity 0.25s ease-in-out; }
      .menu--desktop .menu__item:last-child {
        margin-right: 0; }
        .menu--desktop .menu__item:last-child a {
          vertical-align: middle; }
    .menu--desktop .menu__item--has-submenu > .menu__link::after {
      content: '';
      margin-left: 2px;
      margin-left: 0.125rem;
      display: inline-block;
      height: 0;
      width: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid black;
      vertical-align: middle; }
    .menu--desktop .menu__link {
      padding-bottom: 15px;
      padding-bottom: 0.9375rem;
      opacity: 0.7; }
    .menu--desktop .menu__item:hover .menu__link, .menu--desktop .menu__link {
      opacity: 1; }
    .menu--desktop .menu__item--active {
      color: #cc0000; }
    .menu--desktop .menu__links--nested {
      position: absolute;
      left: 0;
      top: 0;
      min-width: 150px;
      padding: 10px 0;
      padding: 0.625rem 0;
      background: white;
      color: black;
      border: 1px solid black;
      white-space: nowrap;
      text-align: left;
      z-index: 3;
      border: 1px solid #CACACA; }
      .menu--desktop .menu__links--nested .menu__item {
        display: block;
        margin: 0;
        padding: 0; }
      .menu--desktop .menu__links--nested .menu__link {
        display: block;
        margin: 0;
        padding: 4px 30px 4px 15px;
        padding: 0.25rem 1.875rem 0.25rem 0.9375rem;
        font-weight: normal;
        font-size: 15px;
        font-size: 0.9375rem;
        text-transform: none;
        transition: none;
        opacity: 1; }
      .menu--desktop .menu__links--nested .menu__item:hover {
        color: white;
        background: black; }
    .menu--desktop .menu__item--has-submenu:hover > .menu__links {
      display: block; }
    .menu--desktop .menu__item--has-submenu:hover ~ .menu__item {
      pointer-events: none; }
    .menu--desktop .menu__links--level-1 {
      top: 100%;
      left: 0;
      margin-top: 8px;
      margin-top: 0.5rem; }
      .menu--desktop .menu__links--level-1::before {
        position: absolute;
        content: '';
        left: 15px;
        left: 0.9375rem;
        top: -6px;
        top: -0.375rem;
        height: 0;
        width: 0;
        border-left: 7.5px solid transparent;
        border-right: 7.5px solid transparent;
        border-bottom: 7.5px solid white;
        -webkit-filter: drop-shadow(0 -2px black);
        filter: drop-shadow(0 -2px black);
        display: none; }
      .menu--desktop .menu__links--level-1 .menu__link::after {
        position: absolute;
        right: 10px;
        right: 0.625rem;
        top: 12px;
        top: 0.75rem;
        border-left: 4px solid black;
        border-bottom: 4px solid transparent;
        border-top: 4px solid transparent; }
      .menu--desktop .menu__links--level-1 .menu__item:hover .menu__link::after {
        border-left-color: white; }
    .menu--desktop .menu__links--level-2 {
      top: -11px;
      top: -0.6875rem;
      left: 100%; }
      .menu--desktop .menu__links--level-2 .menu__link::after {
        display: none; }
    .menu--desktop .mega-nav {
      padding: 15px 0;
      padding: 0.9375rem 0;
      /*left: -225px !important;
      left: -14.0625rem !important;*/
      white-space: nowrap;
      padding-bottom: 40px;
      /*left: -275px !important;
      left: -17.1875rem !important;*/ }
      .menu--desktop .mega-nav::before {
        /*left: 290px !important;
        left: 18.125rem !important;*/ }
    .menu--desktop .mega-nav__image-container {
      display: inline-block;
      margin: 0 15px;
      margin: 0 0.9375rem;
      width: 150px;
      width: 9.375rem;
      font-size: 0;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      vertical-align: middle; }
    .menu--desktop .mega-nav__image {
      max-width: 155px;
      font-size: 0; }
    .menu--desktop .dropdown-column {
      display: inline-block;
      padding: 0 20px;
      padding: 0 1.25rem;
      vertical-align: top; }
    .menu--desktop .dropdown-column__title {
      display: block;
      margin: 8px 0 10px 0;
      margin: 0.5rem 0 0.625rem 0;
      /*padding-bottom: 14px;*/
      padding-bottom: 0.875rem;
      color: black;
      font-weight: bolder;
      font-size: 14px;
      font-size: 0.875rem;
      line-height: 14px;
      text-transform: uppercase;
      /*border-bottom: 1px solid rgba($header-text-color, 0.4);*/
      padding: 10px;
      background: #f1f1f1;
      padding: 10px;
      font-weight: normal; }
    .menu--desktop .dropdown-column__list-link {
      display: block;
      padding: 2px 10px;
      padding: 0.125rem 0;
      font-size: 15px;
      font-size: 0.9375rem;
      text-transform: none;
      font-weight: normal;
      color: black;
      opacity: 0.7; }
      .menu--desktop .dropdown-column__list-link:hover {
        opacity: 1; } }
  @media (min-width: 48em) and (max-width: 1025px) {
    .touchevents .menu--desktop .menu__item--has-submenu > .menu__link {
      pointer-events: none;
      /* This prevent click on touch devices, so that dropdown can be opened */ } }
@media (min-width: 60em) {
  .menu--desktop {
  /*margin-top: 45px;
	margin-top: 2.8125rem;
 	float: right;
	text-align: right;*/
    margin-top: -3rem;
    float: left;
    text-align: left;
    margin-left: 180px; }

  .menu--desktop .menu__item {
    margin-right: 20px;
    margin-right: 1.25rem;
    font-size: 15px; }
  .menu--desktop .menu__item--has-submenu > .menu__link::after {
    margin-left: 8px;
    margin-left: 0.5rem; } }
@media (min-width: 75em) {
  .menu--desktop .menu__item {
    margin-right: 30px;
    margin-right: 1.875rem; } }
/**
 * If we are on the home page, and that the slideshow has been activated, we position the header
 * differently so that it can overlaps the slider
 */
.header {
  padding: 20px 0;
  padding: 1.25rem 0;
  background: white;
  color: black;
  background-position: center center;
  background-size: cover; }

.header__container {
  position: relative; }

.header--pushed {
  padding-bottom: 0 !important; }

.header__logo {
  margin: 0;
  text-align: left; }

.header__logo--text {
  font-size: 30px;
  font-size: 1.875rem;
  font-weight: bolder;
  text-transform: uppercase; }

.header__logo-image {
  max-width: 210px;
  max-height: 60px;
  /* We enforce a low max-height on mobile so that it does not take too much space */
  vertical-align: middle; }

.header__actions {
  display: none; }

/**
 * Mobile nav
 */
.header__mobile-nav {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  color: black;
  z-index: 5; }

.header__mobile-tabs {
  position: relative;
  display: table;
  width: 100%;
  height: 48px;
  height: 3rem;
  table-layout: fixed;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  background: white; }

.header__mobile-tab {
  display: table-cell;
  text-align: center;
  border-right: 1px solid rgba(0, 0, 0, 0.5);
  vertical-align: middle;
  transition: all 0.25s ease-in-out; }
  .header__mobile-tab:last-child {
    border-right: none; }

.header__mobile-icon {
  position: relative;
  display: block;
  padding: 12px 0;
  padding: 0.75rem 0;
  transition: none; }
  .header__mobile-icon svg {
    vertical-align: middle;
    width: 25px;
    width: 1.5625rem;
    height: 25px;
    height: 1.5625rem; }

.header__mobile-content {
  display: none;
  position: absolute;
  max-height: calc(100vh - 48px);
  width: 100%;
  top: 100%;
  left: 0;
  background: black;
  border-bottom: 1px solid #e4e4e4;
  overflow: hidden;
  -webkit-overflow-scrolling: touch; }

.header__mobile-tab--open {
  background: black;
  color: white;
  box-shadow: 0 1px black, -1px 0 black; }

.header__mobile-search {
  padding: 20px;
  padding: 1.25rem; }

.mobile-search__input {
  color: black; }

.header__cart-count {
  position: absolute;
  width: 18px;
  width: 1.125rem;
  height: 18px;
  height: 1.125rem;
  top: 5px;
  top: 0.3125rem;
  font-size: 10px;
  font-size: 0.625rem;
  background: black;
  color: white;
  border-radius: 100%;
  text-align: center;
  line-height: 18px; }

/**
 * Push
 */
.header__push {
  clear: both;
  margin-top: 0.125rem;
  /*rgin-top: 20px;
  margin-top: 1.25rem;*/
  color: black; }
  .header__push .inner {
    padding-top: 18px;
    padding-top: 1.125rem;
    background: #fdfdfd; }

.header__push-meta {
  display: none; }

.header__push-content {
  /*padding-bottom: 18px;
  padding-bottom: 1.125rem;
  border-bottom: 1px solid $border-color;*/
  padding-bottom: 0;
  font-weight: bold; }

.header__push-title {
  display: block;
  margin: 0;
  font-family: Open Sans;
  font-size: 18px;
  font-size: 1.125rem;
  text-transform: uppercase;
  text-align: center; }

.header__push-context {
  display: block;
  margin-top: 10px;
  margin-top: 0.625rem;
  padding-left: 15px;
  padding-left: 0.9375rem;
  text-transform: uppercase;
  color: black;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bolder;
  text-align: center; }

.header__push-tags {
  display: none; }

.header__push-filter {
  color: black;
  margin-top: 15px;
  margin-top: 0.9375rem; }

@media (max-width: 47.9em) {
  .header:not(.gift-card__header) {
    background-image: none !important; }

  .cart-check {
    clear: both;
    margin-top: 15px; } }
@media (min-width: 48em) {
  .header {
    /* padding: 35px 0;
     padding: 2.1875rem 0;*/
    padding: 8px 0;
    background-size: cover; }

  .header__logo {
    display: block;
    /*  text-align: center;  */
    text-align: left;
    float: left; }

  .header__mobile-nav {
    display: none; }

  .header__logo-image {
    max-height: none; }

  .header__actions {
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    padding-right: 20px;
    padding-right: 1.25rem;
    font-size: 12px;
    font-size: 0.75rem;
    font-weight: bolder;
    text-transform: uppercase; }

  .header__actions-item {
    display: inline-block; }
    .header__actions-item:not(:last-child)::after {
      display: inline-block;
      content: '';
      margin: 0 12px;
      margin: 0 0.75rem;
      height: 16px;
      height: 1rem;
      width: 1px;
      width: 0.0625rem;
      background: black;
      vertical-align: text-bottom; }

  .header__push-meta {
    display: inline-block;
    float: right;
    font-size: 12px;
    font-size: 0.75rem;
    text-transform: uppercase; }

  .header__push-title {
    display: inline-block;
    font-size: 22px;
    font-size: 1.375rem;
    text-align: left; }

  .header__push-context {
    display: inline-block;
    margin: 0 0 0 15px;
    margin: 0 0 0 0.9375rem;
    padding-left: 15px;
    padding-left: 0.9375rem;
    border-left: 1px solid #e4e4e4;
    vertical-align: super;
    text-align: left; }

  .header__push-tags {
    display: block;
    margin-top: 25px;
    margin-top: 1.5625rem;
    color: black; }
    .header__push-tags.header__push-context {
      display: inline-block;
      margin-top: 0; }

  .header__push-tag {
    display: inline-block;
    margin-right: 20px;
    margin-right: 1.25rem;
    padding-bottom: 2px;
    padding-bottom: 0.125rem;
    text-transform: uppercase;
    font-size: 14px;
    font-size: 0.875rem;
    font-weight: bolder; }
    .header__push-tag:last-child {
      margin-right: 0; }
    .header__push-tag a {
      transition: none; }
    .header__push-tag:hover a {
      border-bottom: 2px solid #1790d3; }

  .header__push-tag--active {
    color: #1790d3; }
    .header__push-tag--active a {
      border-bottom: 2px solid #1790d3; }

  .header__push-filter--tags {
    display: none; }

  .header__push-filter--sort {
    display: inline-block;
    float: right;
    height: 36px;
    height: 2.25rem;
    margin-top: 0;
    max-width: 250px; }
    .header__push-filter--sort select {
      height: 36px;
      height: 2.25rem; } }
@media (min-width: 48em) {
  .header__push {
    /* margin-top: 35px;
     margin-top: 2.1875rem; */ } }
@media (min-width: 60em) {
  .header__push {
    /*margin-top: 50px;
   
margin-top: 0.125rem;*/ }

  .header__push-title {
    font-size: 28px;
    font-size: 1.75rem; }

  .header__logo {
    display: inline-block;
    text-align: left; } }
/**
 * ----------------------------------------------------------------------------------------------------
 * FOOTER
 * ----------------------------------------------------------------------------------------------------
 */
.footer {
  margin-top: 25px;
  margin-top: 1.5625rem;
  padding: 20px 0;
  padding: 1.25rem 0;
  background: white;
  color: #6f6f6f;
  font-size: 14.4px;
  font-size: 0.9rem;
  text-align: center; }
  .footer p {
    margin-bottom: 0; }
  .footer a:hover {
    color: #959595; }

.footer--no-margin {
  margin-top: 0; }

.footer__module {
  padding: 10px;
  /*padding: 1.25rem;
  border: 1px solid $footer-border-color;
  padding: 0.875rem;*/ }
  .footer__module + .footer__module {
    border-top: none; }

.footer__module + .footer__module,
.footer__row + .footer__row .footer__module:first-child {
  border-top: none; }

.footer__module--centered {
  text-align: center;
  vertical-align: middle; }

.footer__title {
  margin-bottom: 10px;
  margin-bottom: 0.625rem;
  /*font-size: 11px * $footer-font-factor;
  font-size: 0.6875rem * $footer-font-factor;*/
  font-size: 19px;
  font-weight: bolder;
  color: #f6bb42;
  text-transform: uppercase; }

.footer__logo {
  text-align: center;
  vertical-align: middle;
  max-width: 150px;
  opacity: 0.5;
  transition: opacity 0.2s ease-in-out;
  will-change: opacity; }
  .footer__logo:hover {
    opacity: 1; }

.footer__linklist {
  margin-bottom: -8px;
  margin-bottom: -0.5rem;
  /* compensate the padding of <li> */
  -webkit-columns: 2;
  -moz-columns: 2;
  columns: 2;
  -webkit-column-gap: 15px;
  -moz-column-gap: 15px;
  column-gap: 15px; }
  .footer__linklist li {
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
    padding-bottom: 8px;
    padding-bottom: 0.5rem; }

.footer__about-text {
  white-space: pre-line; }

.footer__social .social__item {
  display: inline-block;
  /*padding: 0.875rem;*/
  padding: 6px 6px 6px 0px; }
  .footer__social .social__item:not(:last-child) {
    margin-right: 10px;
    margin-right: 0.625rem; }
.footer__social svg {
  height: 15px;
  height: 0.9375rem;
  width: 15px;
  width: 0.9375rem; }

.footer__form {
  position: relative; }

.footer__input,
.footer__submit {
  padding: 12px 15px;
  padding: 0.75rem 0.9375rem; }

.footer__input {
  /*border: none;
  background: $footer-text-color;
  background: rgba($footer-text-color, 0.2);*/
  border-bottom: 1px solid #ccc;
  border-top: none;
  border-left: none;
  border-right: none;
  padding: 6px; }
  .footer__input::-webkit-input-placeholder {
    color: #6f6f6f; }
  .footer__input::-moz-placeholder {
    color: #6f6f6f; }
  .footer__input:-ms-input-placeholder {
    color: #6f6f6f; }
  .footer__input::placeholder {
    color: #6f6f6f; }

.footer__submit {
  /*position: absolute;
  right: 0;
  top: 0;
  width: auto;
  background: none;
  border: none;
  text-align: right;
  transition: color 0.25s ease-in-out;*/
  position: static;
  right: 0;
  margin-top: 20px;
  width: auto;
  background: none;
  border: 2px solid #000d41;
  text-align: right;
  transition: color 0.25s ease-in-out;
  text-transform: uppercase; }
  .footer__submit:hover {
    color: #959595; }

.footer__misc {
  /*margin-top: 20px;
  margin-top: 1.25rem;*/
  margin-top: 0px; }

.footer__copyright {
  font-size: 14.4px;
  font-size: 0.9rem; }

.payment-methods {
  margin-top: 10px;
  margin-top: 0.625rem; }

.payment-methods__item {
  display: inline-block; }
  .payment-methods__item:not(:last-child) {
    margin-right: 10px;
    margin-right: 0.625rem; }
  .payment-methods__item .icon {
    width: 32px;
    width: 2rem;
    height: 32px;
    height: 2rem; }

@media (min-width: 48em) {
  .footer {
    text-align: left; }

  .footer__row {
    display: table;
    width: 100%;
    margin: 0;
    /*border-top: 1px solid $footer-border-color;*/
    border-top: 1px solid #d0d0d0; }
    .footer__row + .footer__row {
      border-top: 0; }

  .footer__module {
    display: table-cell;
    margin-right: 15px;
    margin-right: 0.9375rem;
    padding: 20px 30px;
    padding: 1.25rem 1.875rem;
    border-width: 0 1px 0 0; }
    .footer__module:last-child {
      border-right: none; }

  .footer__linklist a {
    white-space: nowrap; }

  .footer__copyright {
    display: inline-block; }

  .payment-methods {
    margin-top: -8px;
    margin-top: -0.5rem;
    float: right; } }
@media (min-width: 60em) {
  .footer__linklist li {
    white-space: nowrap; } }
@media (min-width: 75em) {
  .footer {
    padding: 40px 0 30px 0;
    padding: 2.5rem 0 1.875rem 0; }

  .footer__module {
    padding: 30px;
    padding: 1.875rem; }

  .footer__linklist {
    -webkit-columns: 3;
    -moz-columns: 3;
    columns: 3;
    -webkit-column-gap: 30px;
    -moz-column-gap: 30px;
    column-gap: 30px; }

  .footer__misc {
    margin-top: 0px;
    /*margin-top: 30px;
    margin-top: 1.875rem;*/ } }
.slideshow,
.slideshow__slide {
  position: relative; }

.slideshow__slides {
  opacity: 0;
  transition: opacity 0.2s cubic-bezier(0.25, 0.45, 0.45, 0.9);
  will-change: opacity; }

.slick-initialized.slideshow__slides {
  opacity: 1; }

.slideshow__image {
  width: 100%; }

.slideshow__cover {
  position: absolute;
  width: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); }

.slideshow__heading {
  font-family: Playfair Display;
  font-size: 20px;
  font-size: 1.25rem;
  color: white; }

.slideshow__subheading {
  display: none;
  /* Do not display on mobile */
  font-family: Montserrat;
  color: #ec7067; }

.slideshow__button {
  background: white;
  color: #ec7067; }
  .no-touchevents .slideshow__button:hover {
    background: #ec7067;
    color: white; }

.slideshow__arrows {
  position: absolute;
  width: 100%;
  bottom: 0;
  right: 0;
  padding-bottom: 5px;
  padding-bottom: 0.3125rem;
  text-align: right;
  color: white; }

.slideshow__arrow {
  display: inline-block; }
  .slideshow__arrow svg {
    width: 24px;
    width: 1.5rem;
    height: 24px;
    height: 1.5rem; }

.slideshow__prev {
  margin-right: 6px;
  margin-right: 0.375rem; }

@media (min-width: 48em) {
  .slideshow {
    min-height: 350px; }

  .slideshow__cover {
    margin-top: 80px;
    margin-top: 5rem; }

  .slideshow--uncropped .slideshow__slide {
    background-image: none !important; }

  .slideshow--cropped .slideshow__slide {
    min-height: 600px;
    background-position: center center;
    background-size: cover; }
  .slideshow--cropped .slideshow__image {
    display: none !important; }
  .slideshow--cropped .slideshow__cover {
    position: relative;
    margin: 305px 0 175px 0;
    margin: 19.0625rem 0 10.9375rem 0;
    width: 100%;
    top: 0;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none; }

  .slideshow__heading {
    margin-bottom: 25px;
    margin-bottom: 1.5625rem;
    font-size: 40px;
    font-size: 2.5rem; }

  .slideshow__subheading {
    display: block;
    text-transform: uppercase;
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: bolder; }
    .slideshow__subheading > span {
      padding-bottom: 4px;
      padding-bottom: 0.25rem;
      border-bottom: 4px solid currentColor; }

  .slideshow__arrow svg {
    width: 34px;
    width: 2.125rem;
    height: 34px;
    height: 2.125rem; }

  /**
   * If the marketing module is enabled, the marketing module will slightly "eat up" over the slider. As
   * a consequence, we need to "move a bit" the content
   */
  .slideshow__arrows {
    bottom: 40px;
    bottom: 2.5rem; } }
@media (min-width: 60em) {
  .slideshow--cropped .slideshow__cover {
    margin: 275px 0 225px 0;
    margin: 17.1875rem 0 14.0625rem 0; }

  .slideshow--uncropped .slideshow__cover {
    margin-top: 25px;
    margin-top: 1.5625rem; }

  .slideshow__arrows {
    bottom: 80px;
    bottom: 5rem; } }
@media (min-width: 75em) {
  .slideshow__heading {
    font-size: 80px;
    font-size: 5rem; }

  .slideshow__arrows {
    bottom: 120px;
    bottom: 7.5rem; } }
.marketing-module {
  /*padding: 0 1.5%;*/
  padding: 0;
  /*padding-top: 1.5%;*/
  padding-top: 0%;
  padding-bottom: 1.5%; }

.marketing-module__wrapper {
  background: #fdfdfd;
  white-space: nowrap;
  font-size: 0; }

.marketing-module__column {
  display: inline-block;
  width: 100%;
  vertical-align: top;
  white-space: normal; }

.marketing-module__link {
  transition: opacity 0.25s ease-in-out;
  will-change: opacity; }
  .marketing-module__link:hover {
    opacity: 0.7; }

.marketing-module__item {
  width: 100%;
  display: inline-block;
  vertical-align: top; }
  .marketing-module__item > .marketing-module__image {
    width: 100%; }

/** Two images on the left, one image on the right **/
.marketing-module__column {
  width: calc(53% - 0.75%); }
  .marketing-module__column + .marketing-module__column {
    margin-left: 1.5%; }

.marketing-module__column:last-child .marketing-module__item:first-child {
  margin-bottom: 1%; }

.marketing-column {
  width: 46%; }

@media (min-width: 48em) {
  .marketing-module {
    display: block; } }
.quick-shop {
  display: table;
  table-layout: fixed;
  padding: 35px 35px 30px 35px;
  padding: 2.1875rem 2.1875rem 1.875rem 2.1875rem;
  width: 850px;
  width: 53.125rem;
  max-width: 100%;
  top: 50% !important;
  left: 50% !important;
  background: #fdfdfd;
  border: 1px solid #e4e4e4;
  border-radius: 5px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
  -webkit-transform: translate(-50%, -50%) !important;
  -ms-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important; }

.quick-shop__slideshow {
  display: table-cell;
  vertical-align: middle;
  width: 40%; }

.quick-shop__product {
  display: table-cell;
  width: 60%;
  padding-left: 50px;
  padding-left: 3.125rem;
  vertical-align: middle; }
  .quick-shop__product .product__form-status {
    position: relative;
    margin-bottom: 45px;
    margin-bottom: 2.8125rem; }

.quick-shop__see-more {
  font-weight: bolder;
  font-size: 14px;
  font-size: 0.875rem;
  text-transform: uppercase; }
  .quick-shop__see-more svg {
    margin-left: 10px;
    margin-left: 0.625rem;
    vertical-align: text-top;
    transition: margin-left 0.25s ease-in-out; }
  .quick-shop__see-more:hover svg {
    margin-left: 15px;
    margin-left: 0.9375rem; }

.mega-search {
  width: 400px;
  width: 25rem; }

.mega-search .icon-cross {
  display: block;
  margin: 0 auto 35px auto;
  margin: 0 auto 2.1875rem auto;
  width: 35px;
  width: 2.1875rem;
  height: 35px;
  height: 2.1875rem;
  fill: #ffffff;
  cursor: pointer; }

.mega-search__form {
  position: relative; }

.mega-search__input {
  padding: 18px 25px;
  padding: 1.125rem 1.5625rem;
  background: transparent;
  border: 3px dashed #ffffff;
  font-size: 18px;
  font-size: 1.125rem;
  color: #ffffff; }
  .mega-search__input:focus {
    border-color: inherit; }
  .mega-search__input::-webkit-input-placeholder {
    color: rgba(255, 255, 255, 0.6); }
  .mega-search__input::-moz-placeholder {
    color: rgba(255, 255, 255, 0.6); }
  .mega-search__input:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.6); }
  .mega-search__input::placeholder {
    color: rgba(255, 255, 255, 0.6); }

.mega-search__spinner {
  position: absolute;
  margin: 0;
  width: 25px;
  width: 1.5625rem;
  height: 25px;
  height: 1.5625rem;
  top: 20px;
  top: 1.25rem;
  right: 20px;
  right: 1.25rem; }

.mega-search__suggestions {
  position: relative;
  display: block !important;
  top: -3px;
  top: -0.1875rem;
  background: #ffffff;
  border-radius: 0 0 3px 3px; }

.mega-search__suggestion {
  padding: 10px;
  padding: 0.625rem;
  cursor: pointer;
  /* Last one is the "display all results" link */ }
  .mega-search__suggestion > div {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center; }
  .mega-search__suggestion:last-child {
    border-top: 1px solid #e4e4e4; }

.mega-search__image {
  display: inline-block;
  max-width: 35px;
  max-height: 35px;
  margin-right: 20px;
  margin-right: 1.25rem;
  border-radius: 3px;
  vertical-align: baseline; }

.mega-search__info {
  display: inline-block; }

.mega-search__item-title {
  display: block;
  color: black;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: 400; }

.mega-search__item-subtitle {
  display: block;
  font-size: 13px;
  font-size: 0.8125rem;
  text-transform: uppercase; }

.mega-search__all-results {
  color: black;
  text-transform: uppercase;
  font-size: 13px;
  font-size: 0.8125rem;
  font-weight: bolder; }

.mega-search__suggestion.selected {
  background: #1790d3; }
  .mega-search__suggestion.selected:last-child {
    border-radius: 0 0 3px 3px; }
  .mega-search__suggestion.selected .mega-search__item-title,
  .mega-search__suggestion.selected .mega-search__item-subtitle,
  .mega-search__suggestion.selected .mega-search__all-results {
    color: white; }

.share-buttons__label {
  display: inline-block;
  margin-right: 25px;
  margin-right: 1.5625rem;
  text-transform: uppercase;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bolder;
  vertical-align: text-bottom; }

.share-buttons__list {
  display: inline-block; }

.share-buttons__item {
  display: inline-block; }
  .share-buttons__item:not(:last-child) {
    margin-right: 8px;
    margin-right: 0.5rem; }
  .share-buttons__item:hover {
    color: #1790d3; }

.article__comments {
  max-width: 900px;
  margin: 0 auto 40px auto;
  margin: 0 auto 2.5rem auto; }

.comments__count {
  margin-top: -10px;
  margin-top: -0.625rem;
  text-align: center;
  font-weight: bolder;
  color: black; }

.comments__moderated {
  display: block;
  margin-top: 10px;
  margin-top: 0.625rem;
  text-align: center; }

.comments__new {
  max-width: 700px;
  margin: 0 auto; }

.comments__section-title {
  text-align: center;
  color: black; }

.comment {
  padding: 25px 0;
  padding: 1.5625rem 0;
  border-bottom: 1px solid #e4e4e4; }
  .comment:last-child {
    border-bottom: none; }

.comment__header {
  margin-bottom: 25px;
  margin-bottom: 1.5625rem;
  text-transform: uppercase;
  font-size: 14px;
  font-size: 0.875rem;
  overflow: hidden; }

.comment__gravatar {
  float: left;
  margin-right: 15px;
  margin-right: 0.9375rem; }

.comment__author {
  margin-bottom: 5px;
  margin-bottom: 0.3125rem;
  padding-top: 10px;
  padding-top: 0.625rem;
  font-weight: bolder; }

.comment__gravatar {
  max-width: 60px;
  border-radius: 100%; }

.comment__content {
  clear: both; }

@media (min-width: 48em) {
  .comment {
    display: table;
    width: 100%;
    table-layout: fixed; }

  .comment__header {
    display: table-cell;
    width: 200px;
    width: 12.5rem; }

  .comment__gravatar {
    float: none; }

  .comment__content {
    display: table-cell;
    padding-left: 60px;
    padding-left: 3.75rem;
    vertical-align: top; } }
@media (min-width: 60em) {
  .article__comments {
    margin-bottom: 80px;
    margin-bottom: 5rem; } }
.error-404 {
  text-align: center; }

.error-404__icon {
  display: inline-block;
  padding: 20px 40px;
  padding: 1.25rem 2.5rem;
  color: #1790d3;
  border: 1px solid #1790d3;
  border-radius: 12px;
  font-size: 64px;
  font-size: 4rem;
  font-weight: bolder; }

.error-404__empty {
  margin: 50px 0;
  margin: 3.125rem 0; }

.login__forgot-password {
  float: right;
  font-weight: normal; }

/**
 * Account dashboard
 */
.account__orders {
  margin-bottom: 20px;
  margin-bottom: 1.25rem; }

@media (min-width: 60em) {
  .account__orders {
    margin-bottom: 0;
    float: left;
    margin-right: 50px;
    width: calc(75% - 50px); }

  .account__address {
    float: left;
    margin-right: 50px;
    width: calc(25%);
    float: right !important;
    margin-right: 0 !important; } }
/**
 * Address
 */
.address {
  position: relative;
  padding: 15px;
  padding: 0.9375rem;
  border: 1px solid #e4e4e4;
  border-radius: 3px; }

.address__type {
  position: absolute;
  display: inline-block;
  width: 100%;
  top: 0;
  left: 0;
  padding: 6px 14px 4px 14px;
  padding: 0.375rem 0.875rem 0.25rem 0.875rem;
  border-radius: 3px 3px 0 0;
  font-size: 12px;
  font-size: 0.75rem;
  text-transform: uppercase;
  font-weight: bolder;
  text-align: center;
  color: #fdfdfd;
  background: #1790d3; }
  .address__type + .address__customer {
    margin-top: 35px;
    margin-top: 2.1875rem; }

.address__customer {
  font-weight: bolder;
  font-size: 18px;
  font-size: 1.125rem;
  color: black; }

.address__details p {
  margin-bottom: 5px;
  margin-bottom: 0.3125rem; }
.address__details + .address__see-all {
  display: inline-block;
  margin-top: 12px;
  margin-top: 0.75rem; }

.address__actions {
  margin-top: 20px;
  margin-top: 1.25rem; }
  .address__actions .button, .address__actions .spr-summary-actions-newreview,
  .address__actions .spr-button-primary {
    display: block;
    text-align: center; }
    .address__actions .button:first-child, .address__actions .spr-summary-actions-newreview:first-child,
    .address__actions .spr-button-primary:first-child {
      margin-bottom: 10px;
      margin-bottom: 0.625rem; }

/**
 * Addresses
 */
.addresses__empty {
  text-align: center;
  margin: 50px 0;
  margin: 3.125rem 0; }

.addresses__modal {
  margin: 25px 0;
  margin: 1.5625rem 0;
  max-height: calc(100% - 50px);
  overflow: scroll;
  -webkit-overflow-scrolling: touch; }

.addresses__close {
  display: block;
  margin-top: 10px;
  margin-top: 0.625rem;
  text-align: center; }

.account__addresses .address {
  margin-bottom: 20px;
  margin-bottom: 1.25rem; }

@media (min-width: 48em) {
  .flexbox .account__addresses {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
    .flexbox .account__addresses .address {
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between; } }
@media (min-width: 48em) and (max-width: 74.9em) {
  .account__addresses .address {
    float: left;
    width: calc(50% + -12.5px);
    margin-left: 25px;
    /*
     This beautiful code allows to remove the bottom margin on unbalanced grid. For instance, if you have
     a 3x3 grids, but the last row only has one product, it will remove the margin JUST for this last one,
     and not the latest 3 ones
    */ }
    .account__addresses .address:nth-child(2n+1):nth-last-child(-n+2), .account__addresses .address:nth-child(2n+1):nth-last-child(-n+2) ~ * {
      margin-bottom: 0; }
    .account__addresses .address:nth-child(2n+1) {
      margin-left: 0;
      clear: both; } }
@media (min-width: 75em) {
  .account__addresses .address {
    float: left;
    width: calc(33.33333% + -16.66667px);
    margin-left: 25px;
    /*
     This beautiful code allows to remove the bottom margin on unbalanced grid. For instance, if you have
     a 3x3 grids, but the last row only has one product, it will remove the margin JUST for this last one,
     and not the latest 3 ones
    */ }
    .account__addresses .address:nth-child(3n+1):nth-last-child(-n+3), .account__addresses .address:nth-child(3n+1):nth-last-child(-n+3) ~ * {
      margin-bottom: 0; }
    .account__addresses .address:nth-child(3n+1) {
      margin-left: 0;
      clear: both; } }
/**
 * Orders
 */
.order__table {
  margin-bottom: 50px;
  margin-bottom: 3.125rem; }

.order__address {
  margin-bottom: 20px;
  margin-bottom: 1.25rem; }

@media (min-width: 48em) {
  .order__address {
    float: left;
    width: calc(50% + -12.5px);
    margin-left: 25px;
    /*
     This beautiful code allows to remove the bottom margin on unbalanced grid. For instance, if you have
     a 3x3 grids, but the last row only has one product, it will remove the margin JUST for this last one,
     and not the latest 3 ones
    */ }
    .order__address:nth-child(2n+1):nth-last-child(-n+2), .order__address:nth-child(2n+1):nth-last-child(-n+2) ~ * {
      margin-bottom: 0; }
    .order__address:first-child {
      margin-left: 0;
      clear: both; } }
.collections {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 25px;
  margin-top: 1.5625rem; }

.collections__item {
  position: relative;
  font-size: 0;
  margin-bottom: 15px;
  margin-bottom: 0.9375rem;
  text-align: center; }

.collections__image {
  display: block;
  margin: 0 auto; }

.collections__overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2); }

.collections__title {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  padding: 0 10px;
  padding: 0 0.625rem;
  font-family: Open Sans;
  color: #ffffff;
  font-size: 20px;
  font-size: 1.25rem;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); }

.no-touchevents .collections__overlay {
  background: rgba(0, 0, 0, 0.4); }
.no-touchevents .collections__overlay,
.no-touchevents .collections__title {
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
  will-change: opacity; }
.no-touchevents .collections__item:hover .collections__overlay,
.no-touchevents .collections__item:hover .collections__title {
  opacity: 1; }

@media (max-width: 47.9em) {
  .index-module__collections .collections__item,
  .template-list-collections .collections__item,
  .template-page-collections .collections__item {
    margin-bottom: 15px;
    margin-bottom: 0.9375rem; } }
@media (min-width: 48em) and (max-width: 74.9em) {
  .index-module__collections .collections__item,
  .template-list-collections .collections__item,
  .template-page-collections .collections__item {
    float: left;
    width: calc(50% + -12.5px);
    margin-left: 25px;
    /*
     This beautiful code allows to remove the bottom margin on unbalanced grid. For instance, if you have
     a 3x3 grids, but the last row only has one product, it will remove the margin JUST for this last one,
     and not the latest 3 ones
    */
    margin-bottom: 25px;
    margin-bottom: 1.5625rem; }
    .index-module__collections .collections__item:nth-child(2n+1):nth-last-child(-n+2), .index-module__collections .collections__item:nth-child(2n+1):nth-last-child(-n+2) ~ *,
    .template-list-collections .collections__item:nth-child(2n+1):nth-last-child(-n+2),
    .template-list-collections .collections__item:nth-child(2n+1):nth-last-child(-n+2) ~ *,
    .template-page-collections .collections__item:nth-child(2n+1):nth-last-child(-n+2),
    .template-page-collections .collections__item:nth-child(2n+1):nth-last-child(-n+2) ~ * {
      margin-bottom: 0; }
    .index-module__collections .collections__item:nth-child(2n+1),
    .template-list-collections .collections__item:nth-child(2n+1),
    .template-page-collections .collections__item:nth-child(2n+1) {
      margin-left: 0;
      clear: both; } }
@media (min-width: 75em) {
  .template-list-collections .collections__item,
  .template-page-collections .collections__item {
    float: left;
    width: calc(50% + -12.5px);
    margin-left: 25px;
    /*
     This beautiful code allows to remove the bottom margin on unbalanced grid. For instance, if you have
     a 3x3 grids, but the last row only has one product, it will remove the margin JUST for this last one,
     and not the latest 3 ones
    */ }
    .template-list-collections .collections__item:nth-child(2n+1):nth-last-child(-n+2), .template-list-collections .collections__item:nth-child(2n+1):nth-last-child(-n+2) ~ *,
    .template-page-collections .collections__item:nth-child(2n+1):nth-last-child(-n+2),
    .template-page-collections .collections__item:nth-child(2n+1):nth-last-child(-n+2) ~ * {
      margin-bottom: 0; }
    .template-list-collections .collections__item:nth-child(2n+1),
    .template-page-collections .collections__item:nth-child(2n+1) {
      margin-left: 0;
      clear: both; } }
.collection__description {
  margin-bottom: 25px;
  margin-bottom: 1.5625rem;
  padding: 15px 25px;
  padding: 0.9375rem 1.5625rem;
  background: #e6e6e6;
  color: #4f4f4f; }

.collection__empty {
  text-align: center;
  margin: 50px 0;
  margin: 3.125rem 0; }

.collection__list {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

.product-item {
  position: relative;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 25px;
  margin-bottom: 1.5625rem; }

.product-item__image-wrapper {
  position: relative;
  width: 100%; }

.product-item__image {
  display: block;
  margin: 0 auto; }

.product-item__image--alternate {
  display: none; }

.product-item--sold-out .product-item__image-wrapper::before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  opacity: 0.4; }

.no-touchevents .product-item__figure--alternate-image-loaded:hover .product-item__image {
  display: none; }
.no-touchevents .product-item__figure--alternate-image-loaded:hover .product-item__image--alternate {
  display: block; }

.product-item__figure {
  position: relative;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex: 1 0 auto;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  margin: 0 0 15px 0;
  margin: 0 0 0.9375rem 0;
  font-size: 0;
  overflow: hidden; }
  .product-item__figure > a {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    max-width: 100%; }

.product-item .product__labels {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  display: none; }
  .product-item .product__labels .label ~ .label {
    margin-top: 5px;
    margin-top: 0.3125rem; }

.product-item__details {
  text-align: center;
  font-weight: bolder; }

.product-item__colors {
  line-height: 0;
  margin-bottom: 12px;
  margin-bottom: 0.75rem; }

.product-item__color {
  display: inline-block;
  margin: 0 1px;
  margin: 0 0.0625rem;
  padding: 2px;
  padding: 0.125rem;
  border-radius: 100%;
  border: 1px solid #e4e4e4;
  vertical-align: middle; }
  .product-item__color span {
    display: block;
    width: 12px;
    width: 0.75rem;
    height: 12px;
    height: 0.75rem;
    border-radius: 100%;
    background-size: cover; }

.product-item__vendor,
.product-item__type,
.product-item__price {
  text-transform: uppercase;
  font-size: 12px;
  font-size: 0.75rem; }

.product-item__vendor,
.product-item__type {
  display: block;
  margin-bottom: 4px;
  margin-bottom: 0.25rem; }

.product-item__price {
  display: inline-block; }

.product-item__price--old {
  text-decoration: line-through;
  font-weight: normal; }

.product-item__title {
  display: block;
  margin-bottom: 0;
  font-size: 14px;
  font-size: 0.875rem;
  color: black; }

.button--quick-shop {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  text-align: center;
  border-radius: 0; }

.no-touchevents .product-item__figure:hover .button--quick-shop {
  display: block !important; }

@media (max-width: 47.9em) {
  .index-module__products-1 .product-item,
  .index-module__products-2 .product-item,
  .template-collection .product-item,
  .template-product .product-item,
  .template-search .product-item {
    float: left;
    width: calc(50% + -7.5px);
    margin-left: 15px;
    /*
     This beautiful code allows to remove the bottom margin on unbalanced grid. For instance, if you have
     a 3x3 grids, but the last row only has one product, it will remove the margin JUST for this last one,
     and not the latest 3 ones
    */ }
    .index-module__products-1 .product-item:nth-child(2n+1):nth-last-child(-n+2), .index-module__products-1 .product-item:nth-child(2n+1):nth-last-child(-n+2) ~ *,
    .index-module__products-2 .product-item:nth-child(2n+1):nth-last-child(-n+2),
    .index-module__products-2 .product-item:nth-child(2n+1):nth-last-child(-n+2) ~ *,
    .template-collection .product-item:nth-child(2n+1):nth-last-child(-n+2),
    .template-collection .product-item:nth-child(2n+1):nth-last-child(-n+2) ~ *,
    .template-product .product-item:nth-child(2n+1):nth-last-child(-n+2),
    .template-product .product-item:nth-child(2n+1):nth-last-child(-n+2) ~ *,
    .template-search .product-item:nth-child(2n+1):nth-last-child(-n+2),
    .template-search .product-item:nth-child(2n+1):nth-last-child(-n+2) ~ * {
      margin-bottom: 0; }
    .index-module__products-1 .product-item:nth-child(2n+1),
    .index-module__products-2 .product-item:nth-child(2n+1),
    .template-collection .product-item:nth-child(2n+1),
    .template-product .product-item:nth-child(2n+1),
    .template-search .product-item:nth-child(2n+1) {
      margin-left: 0;
      clear: both; } }
@media (min-width: 48em) and (max-width: 74.9em) {
  .index-module__products-1 .product-item,
  .index-module__products-2 .product-item,
  .template-collection .product-item,
  .template-product .product-item,
  .template-search .product-item {
    float: left;
    width: calc(33.33333% + -16.66667px);
    margin-left: 25px;
    /*
     This beautiful code allows to remove the bottom margin on unbalanced grid. For instance, if you have
     a 3x3 grids, but the last row only has one product, it will remove the margin JUST for this last one,
     and not the latest 3 ones
    */ }
    .index-module__products-1 .product-item:nth-child(3n+1):nth-last-child(-n+3), .index-module__products-1 .product-item:nth-child(3n+1):nth-last-child(-n+3) ~ *,
    .index-module__products-2 .product-item:nth-child(3n+1):nth-last-child(-n+3),
    .index-module__products-2 .product-item:nth-child(3n+1):nth-last-child(-n+3) ~ *,
    .template-collection .product-item:nth-child(3n+1):nth-last-child(-n+3),
    .template-collection .product-item:nth-child(3n+1):nth-last-child(-n+3) ~ *,
    .template-product .product-item:nth-child(3n+1):nth-last-child(-n+3),
    .template-product .product-item:nth-child(3n+1):nth-last-child(-n+3) ~ *,
    .template-search .product-item:nth-child(3n+1):nth-last-child(-n+3),
    .template-search .product-item:nth-child(3n+1):nth-last-child(-n+3) ~ * {
      margin-bottom: 0; }
    .index-module__products-1 .product-item:nth-child(3n+1),
    .index-module__products-2 .product-item:nth-child(3n+1),
    .template-collection .product-item:nth-child(3n+1),
    .template-product .product-item:nth-child(3n+1),
    .template-search .product-item:nth-child(3n+1) {
      margin-left: 0;
      clear: both; } }
@media (min-width: 48em) {
  .product-item {
    margin-bottom: 50px;
    margin-bottom: 3.125rem; } }
@media (min-width: 75em) {
  .template-collection .product-item,
  .template-search .product-item {
    float: left;
    width: calc(33.33333% + -16.66667px);
    margin-left: 25px;
    /*
     This beautiful code allows to remove the bottom margin on unbalanced grid. For instance, if you have
     a 3x3 grids, but the last row only has one product, it will remove the margin JUST for this last one,
     and not the latest 3 ones
    */ }
    .template-collection .product-item:nth-child(3n+1):nth-last-child(-n+3), .template-collection .product-item:nth-child(3n+1):nth-last-child(-n+3) ~ *,
    .template-search .product-item:nth-child(3n+1):nth-last-child(-n+3),
    .template-search .product-item:nth-child(3n+1):nth-last-child(-n+3) ~ * {
      margin-bottom: 0; }
    .template-collection .product-item:nth-child(3n+1),
    .template-search .product-item:nth-child(3n+1) {
      margin-left: 0;
      clear: both; } }
.cart__empty {
  text-align: center;
  margin: 50px 0;
  margin: 3.125rem 0; }

.cart__table {
  margin-bottom: 20px;
  margin-bottom: 1.25rem; }

.cart__table--desktop {
  display: none; }

.cart-item__image-container {
  max-width: 100px;
  width: 100px;
  width: 6.25rem;
  padding-right: 0; }

.cart-item__image {
  /*  border-right: 1px solid $border-color; */
  padding-right: 15px;
  padding-right: 0.9375rem;
  vertical-align: top; }

.cart-item__vendor,
.cart-item__title,
.cart-item__variant,
.cart-item__properties,
.cart-item__unit-price,
.cart-item__line-price {
  display: block;
  font-size: 13px;
  font-size: 0.8125rem;
  text-transform: uppercase;
  font-weight: bolder; }

.cart-item__vendor,
.cart-item__title,
.cart-item__variant {
  margin-bottom: 8px;
  margin-bottom: 0.5rem; }

.cart-item__title,
.cart-item__line-price {
  color: black; }

.cart-item__title {
  font-size: 14px;
  font-size: 0.875rem; }

.cart-item__properties {
  font-weight: normal; }

.cart-item__quantity {
  max-width: 55px;
  padding-left: 10px;
  text-align: left;
  -moz-appearance: textfield; }
  .cart-item__quantity::-webkit-inner-spin-button, .cart-item__quantity::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0; }

.cart-item__remove:hover {
  color: #1790d3; }
.cart-item__remove svg {
  width: 20px;
  width: 1.25rem;
  height: 20px;
  height: 1.25rem; }

.cart__table--mobile .cart-item__properties {
  margin-bottom: 8px;
  margin-bottom: 0.5rem; }
.cart__table--mobile .cart-item__quantity {
  display: inline-block;
  width: 55px;
  width: 3.75rem;
  vertical-align: top; }
.cart__table--mobile .cart-item__price-and-remove {
  display: inline-block;
  margin-left: 15px;
  margin-left: 0.9375rem; }

.cart__total {
  margin-bottom: 35px;
  margin-bottom: 2.1875rem;
  font-size: 18px;
  font-size: 1.125rem;
  text-transform: uppercase;
  text-align: center; }

.cart__total-amount {
  color: black;
  font-size: 22px;
  font-size: 1.375rem; }

.cart__instructions {
  margin-bottom: 15px;
  margin-bottom: 0.9375rem; }

.cart__checkout-with {
  margin-top: 15px;
  margin-top: 0.9375rem;
  text-align: center; }
  .cart__checkout-with input {
    width: auto;
    border: none; }
  .cart__checkout-with > *:not(script) {
    display: block !important;
    margin: 0 auto;
    padding: 10px 0 0 10px;
    padding: 0.625rem 0 0 0.625rem;
    vertical-align: top;
    line-height: 1; }
    .cart__checkout-with > *:not(script):first-child, .cart__checkout-with > *:not(script):empty {
      padding-left: 0; }
  .cart__checkout-with > p {
    padding: 0;
    margin: 0;
    font-size: 13px;
    font-size: 0.8125rem;
    text-transform: uppercase;
    font-weight: bolder; }

.cart__taxes {
  display: block;
  font-size: 15px;
  font-size: 0.9375rem; }

/**
 * Shipping estimator
 */
.shipping-estimator {
  margin-top: 45px;
  margin-top: 2.8125rem;
  padding-top: 25px;
  padding-top: 1.5625rem;
  border-top: 1px solid #e4e4e4; }

.shipping-estimator__title {
  color: black;
  text-align: center; }

.shipping-estimator__results {
  margin-top: 30px;
  margin-top: 1.875rem; }

@media (min-width: 48em) {
  .cart__table--mobile {
    display: none; }

  .cart__table--desktop {
    display: table; }

  .cart__table {
    border: none;
    box-shadow: none;
    border-collapse: separate;
    /*border-spacing: 0 14px;
     width: 65%;*/
    border-spacing: 0 0px;
    float: left; }
    .cart__table thead {
      font-size: 14px;
      font-size: 0.875rem; }
    .cart__table th {
      background: none;
      border-bottom: 0px; }

  .cart__item {
  /*  td {
      border-top: 1px solid $border-color;
      border-bottom: 1px solid $border-color !important;

      &:first-child {
        border-left: 1px solid $border-color;
      }

      &:last-child {
        border-right: 1px solid $border-color;
      }
    } */ }

  .cart__total {
    font-size: 22px;
    font-size: 1.375rem;
    text-align: right; }

  .cart__total-amount {
    font-size: 26px;
    font-family: -webkit-body;
    line-height: 22px; }

  .cart__instructions {
    float: left;
    margin-right: 50px;
    width: calc(66.66667% - 50px);
    margin-top: -6px;
    margin-top: -0.375rem; }

  .cart__checkout {
    /* @include span(4, 50px, true); */ }

  .cart__checkout-with {
    text-align: center; }
    .cart__checkout-with input {
      margin-right: 0; }

  .flexbox .shipping-estimator__form {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center; }
    .flexbox .shipping-estimator__form .form__control {
      margin: 0 15px 0 0;
      margin: 0 0.9375rem 0 0; }
  .flexbox .shipping-estimator__zip {
    max-width: 160px; } }
@media (min-width: 60em) {
  .cart-item__image-container {
    max-width: 135px;
    width: 135px;
    width: 8.4375rem; }

  .cart__instructions {
    float: left;
    margin-right: 50px;
    width: calc(50% - 50px); } }
.contact {
  margin-top: 40px;
  margin-top: 2.5rem; }

@media (min-width: 48em) {
  .template-page-contact .page__content {
    float: left;
    margin-right: 50px;
    width: calc(50% - 50px); }
  .template-page-contact .contact {
    margin-top: 0; }
  .template-page-contact .page__content + .contact {
    float: left;
    margin-right: 50px;
    width: calc(50%);
    float: right !important;
    margin-right: 0 !important;
    margin-top: 0; } }
@media (min-width: 60em) {
  .template-page-contact .page__content {
    float: left;
    margin-right: 50px;
    width: calc(58.33333% - 50px); }
  .template-page-contact .page__content + .contact {
    float: left;
    margin-right: 50px;
    width: calc(41.66667%);
    float: right !important;
    margin-right: 0 !important;
    margin-top: 0; } }
.header__push-rss {
  display: none; }

/**
 * BLOG
 */
.blog__articles {
  text-align: left; }

.blog__article {
  margin-bottom: 60px;
  margin-bottom: 3.75rem; }

@media (min-width: 48em) {
  .header__push-rss {
    display: inline-block;
    margin-top: 16px;
    margin-top: 1rem;
    float: right;
    font-size: 12px;
    font-size: 0.75rem;
    text-transform: uppercase;
    color: black; }
    .header__push-rss svg {
      margin-right: 4px;
      margin-right: 0.25rem;
      vertical-align: text-top; }

  .blog__article {
    float: left;
    width: calc(33.33333% + -16.66667px);
    margin-left: 25px;
    /*
     This beautiful code allows to remove the bottom margin on unbalanced grid. For instance, if you have
     a 3x3 grids, but the last row only has one product, it will remove the margin JUST for this last one,
     and not the latest 3 ones
    */
    margin-bottom: 100px;
    margin-bottom: 6.25rem; }
    .blog__article:nth-child(3n+1):nth-last-child(-n+3), .blog__article:nth-child(3n+1):nth-last-child(-n+3) ~ * {
      margin-bottom: 0; }
    .blog__article:nth-child(3n + 1) {
      clear: both;
      margin-left: 0; }

  .flexbox .blog__articles {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: auto; }
  .flexbox .blog__article {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between; } }
/**
 * ARTICLE
 */
.article__header {
  margin-bottom: 20px;
  margin-bottom: 1.25rem;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto; }

.article__meta {
  margin-bottom: 5px;
  margin-bottom: 0.3125rem;
  text-transform: uppercase;
  font-size: 14px;
  font-size: 0.875rem; }

.article__meta-separator {
  display: inline-block;
  margin: 0 5px;
  margin: 0 0.3125rem; }

.article__author {
  font-weight: bolder; }

.article__title {
  color: black;
  font-family: Open Sans;
  font-size: 17px;
  font-size: 1.0625rem; }

.article__excerpt {
  margin-bottom: 20px;
  margin-bottom: 1.25rem; }

.article__image {
  display: block;
  margin: 0 auto;
  vertical-align: top; }

.article__comments-count:hover {
  color: #1790d3; }
.article__comments-count svg {
  vertical-align: middle;
  margin-right: 5px;
  margin-right: 0.3125rem; }

.article--full {
  max-width: 900px;
  margin: 0 auto 40px auto;
  margin: 0 auto 2.5rem auto; }
  .article--full .article__meta {
    text-align: center; }
  .article--full .article__image {
    margin: 0 auto 35px auto;
    margin: 0 auto 2.1875rem auto; }
  .article--full .article__body {
    margin-bottom: 50px;
    margin-bottom: 3.125rem; }
  .article--full .article__footer {
    text-align: center; }

.article__tags {
  margin-top: 15px;
  margin-top: 0.9375rem; }

.article__tags-label {
  display: inline-block;
  margin-right: 25px;
  margin-right: 1.5625rem;
  text-transform: uppercase;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bolder;
  vertical-align: middle; }

.article__tags-list {
  display: inline-block;
  font-style: italic; }

.article__tags-item {
  display: inline-block; }
  .article__tags-item:hover {
    color: #1790d3; }

@media (min-width: 60em) {
  .article--full {
    margin-bottom: 80px;
    margin-bottom: 5rem; }

  .article__title {
    font-size: 20px;
    font-size: 1.25rem; } }
.search__empty {
  text-align: center;
  margin: 50px 0;
  margin: 3.125rem 0; }

.search__input {
  max-width: 400px;
  margin: 0 auto 20px auto;
  margin: 0 auto 1.25rem auto; }

/**
 * Navigation
 */
.product__next:hover,
.product__previous:hover {
  color: #1790d3; }
.product__next svg,
.product__previous svg {
  vertical-align: middle; }

.product__previous + .separator {
  display: inline-block;
  margin: 0 5px;
  margin: 0 0.3125rem;
  color: black; }

/**
 * Showcase and details
 */
.product__slideshow {
  margin: 0 -20px;
  margin: 0 -1.25rem;
  /* Allow to expand over whole width */ }
  .product__slideshow .slick-arrow {
    display: none; }
  .product__slideshow .slick-dots {
    margin-top: 10px;
    margin-top: 0.625rem;
    padding: 0;
    text-align: center; }
    .product__slideshow .slick-dots li {
      display: inline-block;
      cursor: pointer; }
    .product__slideshow .slick-dots .slick-active button {
      background: #1790d3; }
    .product__slideshow .slick-dots button {
      display: inline-block;
      margin: 10px 8px;
      margin: 0.625rem 0.5rem;
      padding: 0;
      height: 8px;
      height: 0.5rem;
      width: 8px;
      width: 0.5rem;
      background: black;
      border-radius: 100%;
      cursor: pointer;
      border: none;
      outline: none;
      font-size: 0; }

.product__slideshow-image {
  max-width: 100%;
  margin: 0 auto; }

.product__slideshow-image--zoomed {
  visibility: hidden; }

.product__slideshow--nav {
  display: none; }

.product__meta--desktop {
  display: none; }

.product__meta--mobile {
  margin-bottom: 25px;
  margin-bottom: 1.5625rem;
  text-align: center; }

.product__labels {
  text-align: left; }

.product__meta .product__labels {
  text-align: center; }

.product__labels--always {
  margin-top: 18px;
  margin-top: 1.125rem; }

.product__vendor {
  margin-bottom: 8px;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  font-size: 13px;
  font-size: 0.8125rem;
  font-weight: bolder; }

.product__inventory,
.product__reviews-badge {
  display: block;
  margin-bottom: 12px;
  margin-bottom: 0.75rem;
  font-size: 14px;
  font-size: 0.875rem;
  text-transform: uppercase; }

.spr-badge-caption {
  margin-left: 8px;
  margin-left: 0.5rem; }

.product__title {
  margin-bottom: 10px;
  margin-bottom: 0.625rem;
  font-family: Open Sans;
  font-size: 20px;
  font-size: 1.25rem;
  color: black; }

.product__price {
  font-weight: bolder;
  font-size: 16px;
  font-size: 1rem;
  color: #1790d3; }

.product__price--old {
  margin-right: 15px;
  margin-right: 0.9375rem;
  font-weight: normal;
  text-decoration: line-through;
  color: #000; }

.product__price--new {
  color: #c80000; }

.product__sale-percent {
  margin-left: 15px;
  margin-left: 0.9375rem;
  padding: 5px 10px;
  padding: 0.3125rem 0.625rem;
  color: white;
  background: #e95842;
  font-size: 16px;
  font-size: 1rem;
  font-weight: bolder;
  border-radius: 3px; }

@media (min-width: 48em) {
  .product__slideshow {
    margin: 0; }

  .product__slideshow .slick-dots {
    display: none !important; }

  .product__slideshow--nav {
    /*display: block;
    display: list-item;*/
    margin-top: 25px;
    margin-top: 1.5625rem;
    text-align: center; }

  .flexbox .product__slideshow--nav {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }

  .product__slideshow-nav-image {
    width: 70px;
    width: 4.375rem;
    height: 70px;
    height: 4.375rem;
    margin: 10px;
    margin: 0.625rem;
    object-fit: cover;
    vertical-align: middle;
    cursor: pointer;
    transition: all 0.25s ease-in-out;
    border-radius: 100%;
    border: 1px solid rgba(228, 228, 228, 0.8);
    box-shadow: 0 1px 2px rgba(228, 228, 228, 0.8); }
    .product__slideshow-nav-image:hover {
      border-color: rgba(23, 144, 211, 0.6);
      box-shadow: 0 1px 2px rgba(228, 228, 228, 0.6); } }
@media (min-width: 60em) {
  .product__meta--desktop {
    display: block; }

  .product__meta--mobile {
    display: none; }

  .product__meta .product__labels {
    text-align: left; }

  .product__slideshow {
    float: left;
    margin-right: 45px;
    width: calc(58.33333% - 45px);
    width: calc(60% - 25px); }

  .product__info {
    width: 40% !important;
    display: block;
    float: left;
    margin-right: 45px;
    width: calc(41.66667%);
    float: right !important;
    margin-right: 0 !important; }

  .product__meta {
    padding-bottom: 22px;
    padding-bottom: 1.375rem;
    border-bottom: 1px solid #e4e4e4; }

  .product__title {
    /* font-size: 28px;
     font-size: 1.75rem;
     font-size: 19px;
     font-weight: bold;*/
    font-size: 21px;
    font-family: arial; } }
/**
 * Form
 */
.product__form {
  margin-top: 24px;
  margin-top: 1.5rem;
  padding-bottom: 24px;
  padding-bottom: 1.5rem; }

.product__size-chart {
  display: block;
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: bolder;
  color: black;
  text-decoration: underline;
  text-transform: none; }

.product__quantity {
  width: 90px !important;
  width: 5.625rem !important;
  float: right; }

.product__form-hint {
  margin: 10px 0 -5px 0;
  margin: 0.625rem 0 -0.3125rem 0;
  font-size: 13px;
  font-size: 0.8125rem;
  /*text-align: center;*/
  text-align: left; }

.product__buy {
  position: relative; }

.product__form-status {
  position: absolute;
  width: 100%;
  padding: 16px 10px 25px 10px;
  padding: 1rem 0.625rem 1.5625rem 0.625rem;
  text-align: center;
  border: 1px solid #e4e4e4;
  border-top: none;
  border-radius: 0 0 3px 3px;
  box-shadow: 0 21px 27px 3px #e4e4e4;
  background: #fdfdfd;
  z-index: 2; }
  .product__form-status svg {
    vertical-align: middle;
    margin-left: 10px;
    margin-left: 0.625rem; }

.product__form-message {
  font-weight: bolder; }

.product__form-message--success {
  color: #0bb73a; }

.product__form-message--error {
  color: #e9573f; }

.product__form-continue {
  font-weight: bolder;
  padding-bottom: 3px;
  padding-bottom: 0.1875rem;
  border-bottom: 2px solid black;
  color: black; }

.product__form-or {
  display: block;
  margin: 10px 0;
  margin: 0.625rem 0;
  padding: 4px;
  padding: 0.25rem;
  font-size: 12px;
  font-size: 0.75rem;
  font-style: italic; }

@media (min-width: 48em) {
  .product__form-status {
    padding: 24px 10px 32px 10px;
    padding: 1.5rem 0.625rem 2rem 0.625rem; }

  .product__form-or {
    display: inline-block;
    margin: 0 20px;
    margin: 0 1.25rem; } }
/**
 * Tabs and description
 */
.product__description {
  padding: 25px 0;
  padding: 1.5625rem 0;
  border-top: 1px solid #e4e4e4; }

.product__description-label {
  display: inline-block;
  margin-bottom: 25px;
  margin-bottom: 1.5625rem;
  text-transform: uppercase;
  font-weight: bolder;
  font-size: 14px;
  font-size: 0.875rem;
  border-bottom: 3px solid #e4e4e4; }

.product__tabs-title {
  display: none;
  /* On mobile we use a dropdown insted */ }

.product__tabs {
  clear: both;
  padding-top: 35px;
  padding-top: 2.1875rem; }

.product__tab-selector {
  margin-bottom: 20px;
  margin-bottom: 1.25rem; }

@media (min-width: 48em) {
  .product__tabs {
    /*padding-top: 65px;
    padding-top: 4.0625rem;*/
    padding-top: 1.0625rem; }

  .product__tab-selector {
    display: none; }

  .product__tabs-title {
    display: block;
    text-align: center; }

  .product__tab-title {
    display: inline-block;
    margin: 0 15px;
    margin: 0 0.9375rem;
    text-transform: uppercase;
    opacity: 0.6;
    transition: all 0.25s ease-in-out;
    font-weight: bolder;
    cursor: pointer;
    border-bottom: 2px solid transparent; }
    .product__tab-title:hover {
      opacity: 1; }

  .product__tab-title--active {
    color: black;
    border-bottom-color: black;
    opacity: 1; }

  .product__tabs-content {
    margin: 50px 0 30px 0;
    margin: 3.125rem 0 1.875rem 0; } }
@media (min-width: 75em) {
  .product__tab-title {
    margin: 0 45px;
    margin: 0 2.8125rem; } }
/**
 * Related products
 */
.related-products {
  padding: 2.25rem 0 2.75rem 0;
  margin-top: 2.8125rem;
  /*  margin-top: 0.8125rem;*/
  background: #f3f4f4; }

.related-products__title {
  font-size: 1.875rem; }

.related-products__list {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap; }

@media (min-width: 75em) {
  .related-products .product-item {
    float: left;
    width: calc(25% + -18.75px);
    margin-left: 25px;
    /*
     This beautiful code allows to remove the bottom margin on unbalanced grid. For instance, if you have
     a 3x3 grids, but the last row only has one product, it will remove the margin JUST for this last one,
     and not the latest 3 ones
    */ }
    .related-products .product-item:nth-child(4n+1):nth-last-child(-n+4), .related-products .product-item:nth-child(4n+1):nth-last-child(-n+4) ~ * {
      margin-bottom: 0; }
    .related-products .product-item:nth-child(4n+1) {
      margin-left: 0;
      clear: both; }

  .cart-check {
    float: right;
    width: 39%; } }
.template-gift-card .header {
  padding: 75px 0;
  padding: 4.6875rem 0;
  text-align: center; }
.template-gift-card .main {
  margin-top: 0; }

.gift-card__subheader {
  margin-bottom: 60px;
  margin-bottom: 3.75rem;
  padding: 15px 0;
  padding: 0.9375rem 0;
  font-size: 22px;
  font-size: 1.375rem;
  font-weight: bolder;
  text-transform: uppercase;
  text-align: center;
  background: black;
  color: #fdfdfd; }

.gift-card__details {
  margin-top: 30px;
  margin-top: 1.875rem;
  text-align: center; }

.gift-card__issued-text {
  margin-bottom: 0;
  font-size: 30px;
  font-size: 1.875rem;
  font-weight: lighter; }

.gift-card__amount {
  display: block;
  margin-bottom: 35px;
  margin-bottom: 2.1875rem;
  font-size: 75px;
  font-size: 4.6875rem;
  font-weight: bolder;
  color: black; }

.gift-card__code {
  display: inline-block;
  padding: 8px 20px;
  padding: 0.5rem 1.25rem;
  font-weight: bolder;
  font-size: 26px;
  font-size: 1.625rem;
  text-align: center;
  border: 2px solid #e4e4e4;
  border-radius: 4px; }

.gift-card__qr-code {
  margin: 25px 0;
  margin: 1.5625rem 0; }
  .gift-card__qr-code img {
    margin: 0 auto; }

.gift-card__actions {
  margin-top: 50px;
  margin-top: 3.125rem; }
  .gift-card__actions .button, .gift-card__actions .spr-summary-actions-newreview,
  .gift-card__actions .spr-button-primary {
    display: block;
    margin: 0; }
  .gift-card__actions .button:first-child, .gift-card__actions .spr-summary-actions-newreview:first-child,
  .gift-card__actions .spr-button-primary:first-child {
    margin-bottom: 15px;
    margin-bottom: 0.9375rem; }

.gift-card__apple-wallet {
  display: block;
  margin-bottom: 20px;
  margin-bottom: 1.25rem; }

.gift-card__footer {
  text-align: center; }

.gift-card__copyright {
  margin-top: 50px;
  margin-top: 3.125rem;
  padding: 40px 0;
  padding: 2.5rem 0;
  border-top: 1px solid #e4e4e4;
  font-size: 13px;
  font-size: 0.8125rem; }

@media (min-width: 48em) {
  .gift-card__actions {
    margin-top: 50px;
    /*argin-top: 3.125rem;*/
    margin-top: 0.125rem; }
    .gift-card__actions .button, .gift-card__actions .spr-summary-actions-newreview,
    .gift-card__actions .spr-button-primary {
      display: inline-block; }
    .gift-card__actions .button:first-child, .gift-card__actions .spr-summary-actions-newreview:first-child,
    .gift-card__actions .spr-button-primary:first-child {
      margin-right: 15px;
      margin-right: 0.9375rem;
      margin-bottom: 0; } }
@media (min-width: 60em) {
  .gift-card__subheader {
    font-size: 30px;
    font-size: 1.875rem; }

  .gift-card__issued-text {
    font-size: 55px;
    font-size: 3.4375rem; }

  .gift-card__illustration {
    float: left;
    margin-right: 75px;
    width: calc(41.66667% - 75px);
    text-align: center; }

  .gift-card__details {
    float: left;
    margin-right: 75px;
    width: calc(58.33333%);
    float: right !important;
    margin-right: 0 !important;
    margin-top: 0;
    text-align: left; }

  .gift-card__qr-code img {
    margin-left: 0; } }
.template-password .header {
  padding: 75px 0;
  padding: 4.6875rem 0;
  text-align: center; }
.template-password .main {
  margin-top: 35px;
  margin-top: 2.1875rem;
  text-align: center; }

.password__store,
.password__message {
  text-transform: uppercase;
  font-size: 15px;
  font-size: 0.9375rem;
  font-weight: bolder; }

.password__opening {
  font-family: 'Give You Glory', 'Arial', 'sans-serif';
  font-size: 32px;
  font-size: 2rem;
  color: #1790d3; }

.password__register input[type="email"] {
  font-size: 14px;
  font-size: 0.875rem; }

.password__powered-by svg {
  height: 25px;
  height: 1.5625rem;
  vertical-align: middle; }

.password__footer {
  margin: 80px 0 50px 0;
  margin: 5rem 0 3.125rem 0;
  padding-top: 50px;
  padding-top: 3.125rem;
  border-top: 1px solid #e4e4e4;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: lighter;
  text-align: center; }

.password__admin-form .form__control {
  margin-bottom: 15px;
  margin-bottom: 0.9375rem; }

@media (min-width: 48em) {
  .password__opening {
    font-size: 48px;
    font-size: 3rem; }

  .cart-check {
    float: right;
    width: 39%; } }
@media (min-width: 48em) {
  .password__opening {
    margin-bottom: 50px;
    margin-bottom: 3.125rem;
    font-size: 90px;
    font-size: 5.625rem; }

  .password__powered-by {
    float: left;
    margin-right: 20px;
    width: calc(50% - 20px);
    margin: 10px 0 0 0;
    margin: 0.625rem 0 0 0;
    text-align: left; }

  .password__admin-form {
    float: left;
    margin-right: 20px;
    width: calc(50%);
    float: right !important;
    margin-right: 0 !important; } }
.template-index .main,
.template-index .footer {
  margin-top: 0; }

.index-module, .related-products {
  padding: 20px 0;
  padding: 1.25rem 0;
  text-align: center; }

.index-module__title, .comments__section-title, .shipping-estimator__title, .related-products__title {
  font-family: Open Sans;
  font-size: 22px;
  font-size: 1.375rem;
  margin: 0px; }

.index-module__products-1 {
  color: #8a8787;
  background: #f3f4f4; }
  .index-module__products-1 .product-item__title {
    color: black; }

.index-module__products-2 {
  color: #7a7264;
  background: white; }
  .index-module__products-2 .product-item__title {
    color: black; }

.index-module__collections {
  color: #9f9a91;
  background: white; }

.index-module__free-text-1 {
  color: black;
  background: white; }

.index-module__free-text-2 {
  color: black;
  background: white; }

.index-module__newsletter {
  color: black;
  background: #ada89f; }

.index-module__blog {
  background: #f3f4f4; }
  .index-module__blog .index-module__title, .index-module__blog .comments__section-title, .index-module__blog .shipping-estimator__title, .index-module__blog .related-products__title {
    color: #8a8787; }
  .index-module__blog .blog__article {
    margin-bottom: 20px;
    margin-bottom: 1.25rem;
    padding: 15px;
    padding: 0.9375rem;
    background: #fdfdfd;
    border-radius: 4px; }
  .index-module__blog .article__image {
    max-width: none;
    width: calc(100% + 30px);
    width: calc(100% + 1.875rem);
    margin-left: -15px;
    margin-left: -0.9375rem; }

.index-module__newsletter-form {
  max-width: 650px;
  margin: 25px auto 0 auto;
  margin: 1.5625rem auto 0 auto; }
  .index-module__newsletter-form input {
    border: none; }
  .index-module__newsletter-form input[type="email"] {
    background: #d9d5cc; }
    .index-module__newsletter-form input[type="email"], .index-module__newsletter-form input[type="email"]::-webkit-input-placeholder {
      color: #1a1a1a; }
    .index-module__newsletter-form input[type="email"], .index-module__newsletter-form input[type="email"]::-moz-placeholder {
      color: #1a1a1a; }
    .index-module__newsletter-form input[type="email"], .index-module__newsletter-form input[type="email"]:-ms-input-placeholder {
      color: #1a1a1a; }
    .index-module__newsletter-form input[type="email"], .index-module__newsletter-form input[type="email"]::placeholder {
      color: #1a1a1a; }
  .index-module__newsletter-form input[type="submit"] {
    margin-top: 10px;
    margin-top: 0.625rem;
    background: #1a1a1a;
    color: #ada89f; }
    .index-module__newsletter-form input[type="submit"]:hover {
      background: #0d0d0d; }

.index-module__instagram {
  display: none;
  color: #7a7264;
  background: white; }
  .index-module__instagram .icon-instagram {
    display: inline-block;
    margin-right: 10px;
    margin-right: 0.625rem;
    vertical-align: -1px; }

.instagram__overlay {
  display: none; }

.instagram__image-wrapper {
  position: relative;
  margin-bottom: 10px;
  margin-bottom: 0.625rem;
  font-size: 0; }

.no-touchevents .instagram__overlay {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 18px 15px 16px 20px;
  padding: 1.125rem 0.9375rem 1rem 1.25rem;
  opacity: 0;
  background: rgba(0, 0, 0, 0.6);
  text-align: left;
  transition: opacity 0.25s ease-in-out; }
.no-touchevents .instagram__caption,
.no-touchevents .instagram__date {
  color: #ffffff; }
.no-touchevents .instagram__caption {
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 0;
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 1.55;
  height: 4.65em;
  /* Exactly three lines */ }
.no-touchevents .instagram__date {
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: 700;
  text-transform: uppercase; }
.no-touchevents .instagram__image-wrapper:hover .instagram__overlay {
  opacity: 1; }
.no-touchevents.flexbox .instagram__image-wrapper .instagram__overlay {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between; }

@media (max-width: 47.9em) {
  .instagram__image-wrapper {
    float: left;
    width: calc(50% + -5px);
    margin-left: 10px;
    /*
     This beautiful code allows to remove the bottom margin on unbalanced grid. For instance, if you have
     a 3x3 grids, but the last row only has one product, it will remove the margin JUST for this last one,
     and not the latest 3 ones
    */ }
    .instagram__image-wrapper:nth-child(2n+1):nth-last-child(-n+2), .instagram__image-wrapper:nth-child(2n+1):nth-last-child(-n+2) ~ * {
      margin-bottom: 0; }
    .instagram__image-wrapper:nth-child(2n+1) {
      margin-left: 0;
      clear: both; }

  .policy {
    width: 73%;
    margin: auto; }

  .clear {
    clear: both; }

  #filters {
    display: table;
    /*     margin-left: 30%; */ }

  #filters > .filter-pro:nth-child(1) {
    display: table-header-group;
    margin-left: 30%; }

  #filters > .filter-pro:nth-child(2) {
    display: table-header-group;
    margin-left: 30%; }

  ul.clearfix.filters > li {
    padding-right: 10px !important; }

  .filter-inner {
    padding: 10px !important; } }
@media (min-width: 48em) {
  .index-module, .related-products {
    padding: 30px 0 38px 0;
    padding: 1.875rem 0 2.375rem 0; }

  .index-module__title, .comments__section-title, .shipping-estimator__title, .related-products__title {
    font-size: 26px;
    font-size: 1.625rem; }

  .flexbox .index-module__newsletter-form {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; }
    .flexbox .index-module__newsletter-form input {
      margin-top: 0;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1; }
    .flexbox .index-module__newsletter-form input[type="email"] {
      margin-right: 20px;
      margin-right: 1.25rem;
      -webkit-flex: 2.5;
      -ms-flex: 2.5;
      flex: 2.5; }

  .index-module__instagram {
    display: block; }
    .index-module__instagram .icon-instagram {
      vertical-align: -3px; }

  .instagram__image-wrapper {
    float: left;
    width: calc(16.66667% + -8.33333px);
    margin-left: 10px;
    /*
     This beautiful code allows to remove the bottom margin on unbalanced grid. For instance, if you have
     a 3x3 grids, but the last row only has one product, it will remove the margin JUST for this last one,
     and not the latest 3 ones
    */ }
    .instagram__image-wrapper:nth-child(6n+1):nth-last-child(-n+6), .instagram__image-wrapper:nth-child(6n+1):nth-last-child(-n+6) ~ * {
      margin-bottom: 0; }
    .instagram__image-wrapper:nth-child(6n+1) {
      clear: both;
      margin-left: 0; } }
@media (min-width: 75em) {
  .index-module, .related-products {
    /*padding: 36px 0 44px 0;
    padding: 2.25rem 0 2.75rem 0;*/
    padding: 0rem 0 0rem 0; }

  .filter-pro {
    display: inline;
    width: 68%; }

  #filter {
    width: 60%;
    margin: auto; }

  .margin-bottom {
    margin-bottom: 45px; }

  .index-module__title, .comments__section-title, .shipping-estimator__title, .related-products__title {
    font-size: 30px;
    font-size: 1.875rem; }

  .index-module__products-1 .product-item {
    float: left;
    width: calc(33.33333% + -16.66667px);
    margin-left: 25px;
    /*
     This beautiful code allows to remove the bottom margin on unbalanced grid. For instance, if you have
     a 3x3 grids, but the last row only has one product, it will remove the margin JUST for this last one,
     and not the latest 3 ones
    */ }
    .index-module__products-1 .product-item:nth-child(3n+1):nth-last-child(-n+3), .index-module__products-1 .product-item:nth-child(3n+1):nth-last-child(-n+3) ~ * {
      margin-bottom: 0; }
    .index-module__products-1 .product-item:nth-child(3n+1) {
      margin-left: 0;
      clear: both; }

  .index-module__products-2 .product-item {
    float: left;
    width: calc(33.33333% + -16.66667px);
    margin-left: 25px;
    /*
     This beautiful code allows to remove the bottom margin on unbalanced grid. For instance, if you have
     a 3x3 grids, but the last row only has one product, it will remove the margin JUST for this last one,
     and not the latest 3 ones
    */ }
    .index-module__products-2 .product-item:nth-child(3n+1):nth-last-child(-n+3), .index-module__products-2 .product-item:nth-child(3n+1):nth-last-child(-n+3) ~ * {
      margin-bottom: 0; }
    .index-module__products-2 .product-item:nth-child(3n+1) {
      margin-left: 0;
      clear: both; }

  .index-module__collections .collections__item {
    float: left;
    width: calc(50% + -12.5px);
    margin-left: 25px;
    /*
     This beautiful code allows to remove the bottom margin on unbalanced grid. For instance, if you have
     a 3x3 grids, but the last row only has one product, it will remove the margin JUST for this last one,
     and not the latest 3 ones
    */ }
    .index-module__collections .collections__item:nth-child(2n+1):nth-last-child(-n+2), .index-module__collections .collections__item:nth-child(2n+1):nth-last-child(-n+2) ~ * {
      margin-bottom: 0; }
    .index-module__collections .collections__item:nth-child(2n+1) {
      margin-left: 0;
      clear: both; } }
.listing {
  list-style-type: none; }

.listing li {
  padding-bottom: 5px;
  font-size: 13px; }

.iconns {
  padding: 0.875rem; }

.sclicn {
  width: 160px; }

.shopnowbtn {
  width: 160px;
  background-color: #d19a3d;
  text-align: center;
  padding: 10px;
  color: #fff;
  border-radius: 10px; }

.shopnowbtn a {
  color: #fff; }

.hdrsrch {
  padding: 7px;
  width: 260px;
  margin-top: -104px;
  font-size: 13px;
  color: #a8a8a8;
  border: 1px solid #a8a8a8; }

.srch {
  background-image: url("https://cdn.shopify.com/s/files/1/1187/3410/files/searchbtn.png?4522819817022570660");
  width: 42px;
  border: none;
  height: 34px; }

.search {
  position: relative; }

.search input {
  /*text-indent: 30px;*/ }

.search .fa-search {
  position: absolute;
  top: 45px;
  left: 913px;
  font-size: 15px;
  color: #a8a8a8; }

.ftrlinks {
  color: #5095d6; }

.ftrhdng {
  font-size: 16px;
  font-weight: normal; }

.ftrfirstcolumnlistspacing {
  padding-bottom: 20px; }

@media screen and (max-width: 600px) {
  #hdrarea {
    visibility: hidden;
    display: none; }

  #filters {
    /*   	max-width:320px; */ }

  #cartimg {
    max-width: 70%; }

  #instockdiv {
    max-width: 72%; } }
.menu__links--level-3 {
  margin-left: 217px;
  margin-top: -11px; }

/*
.menu__links--level-3:after{
  background-image:url('http://cdn.shopify.com/s/files/1/1187/3410/products/Product-Pageimg_grande.png?v=1456925837');
  background-repeat:none;
  content: ' ';
  padding:20px;
}*/
.spr-badge {
  border-bottom: 1px solid #e4e4e4; }

/* Edited by Irfan Mumtaz */
.product__price--old {
  font-size: 13px; }

.filter li {
  list-style-type: none; }

.share-buttons__label ~ a {
  margin-right: 5px; }

.yotpo-display-wrapper {
  max-width: 1263px;
  margin: auto !important; }

.cart-button {
  position: relative;
  display: inline-block;
  padding: 15px 40px;
  padding: 7px 10px;
  line-height: normal;
  border: none;
  border-radius: 6px;
  text-transform: uppercase;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: normal;
  transition: all 0.25s ease-in-out;
  cursor: pointer;
  font-family: monospace;
  margin-bottom: 10px; }

.cart-item__left, .table__center {
  vertical-align: top; }

.cart__item > td > a, .cart__item > td > span {
  font-weight: normal;
  font-size: 13px; }

.chk-out {
  background: url("https://cdn.shopify.com/s/files/1/1203/9120/t/2/assets/button.png");
  background-size: contain;
  color: #333; }

.cross-sell li {
  margin: 0 50px !important; }

#cross-sell {
  float: none !important;
  width: 88% !important;
  margin: auto;
  background: #fff;
  max-width: 1263px; }

h3.crossselltitle {
  text-align: center;
  font-size: 22px;
  text-transform: uppercase;
  font-weight: bold; }

.product__description {
  text-align: center; }

.rte > p, .rte {
  text-align: left; }

.product__slideshow--main button {
  display: none !important; }

.product__reviews-badge .write-button {
  display: none; }

.product-stars .yotpo-icon-star::before {
  color: #ffd200;
  content: "\2605"; }

.cat-filter {
  display: block;
  position: absolute;
  background: #fff;
  padding: 8px;
  width: 200px;
  box-shadow: 0px 0px 5px #000;
  top: 310px;
  left: 100px; }

.cat-filter li {
  padding: 10px;
  list-style-type: none; }

.filter-cat {
  width: 200px;
  float: left; }

.filter-cat:hover .cat-filter {
  display: block;
  position: absolute;
  background: #fff;
  padding: 8px;
  width: 200px;
  box-shadow: 0px 0px 5px #000; }

/* filter css code */
.example {
  background: #fff;
  margin: 0px auto;
  padding: 0 15px;
  border-radius: 3px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px; }

/* main menu styles */
.example .menu {
  text-align: center;
  width: 100%; }

.example .menu > span {
  display: inline-block;
  margin: 0 auto; }

#nav {
  display: inline;
  text-align: left;
  position: relative;
  list-style-type: none; }

#nav > li {
  float: left;
  padding: 0;
  position: relative;
  width: 220px; }

#nav > li > a {
  border: 1px solid transparent;
  color: #4F4F4F;
  display: block;
  font-size: 90%;
  padding: 3px 10px;
  position: relative;
  text-decoration: none; }

#nav > li.selected > a {
  z-index: 2; }

#nav li div {
  position: relative; }

#nav li div div {
  background-color: #FFFFFF;
  border: 1px solid #999999;
  padding: 12px 0;
  display: none;
  font-size: 0.75em;
  margin: 0;
  position: absolute;
  top: -1px;
  z-index: 1;
  width: 99.7%; }

#nav li div div.wrp2 {
  width: 380px; }

#nav .sep {
  left: 190px;
  border-left: 1px solid #E3E3E3;
  bottom: 0;
  height: auto;
  margin: 15px 0;
  position: absolute;
  top: 0;
  width: 1px; }

#nav li div ul {
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  width: 170px;
  float: left;
  list-style-type: none; }

#nav li div ul li {
  margin: 0;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: normal; }

#nav li div ul li h3 {
  border-bottom: 1px solid #E3E3E3;
  color: #4F4F4F;
  font-weight: bold;
  margin: 0 5px 4px;
  font-size: 0.95em;
  padding-bottom: 3px;
  padding-top: 3px; }

#nav li ul ul {
  padding: 0 0 8px; }

#nav li ul ul li {
  margin: 0;
  padding: 0; }

#nav li ul ul li a {
  color: #0060A6;
  display: block;
  margin-bottom: 1px;
  padding: 3px 5px;
  text-decoration: none;
  font-size: 0.9em; }

#nav li ul ul li a:hover {
  background-color: #0060a6;
  color: #fff; }

#nav > li {
  border-right: 1px solid #999; }

#nav > li:last-child {
  border-right: none; }

#nav li a p {
  margin: 0; }

#nav li a p:after {
  content: url("https://cdn.shopify.com/s/files/1/1221/8478/t/2/assets/down.png");
  position: absolute;
  right: 10px; }

input[type="checkbox"] {
  margin: 3px 14px 3px 4px; }

.alg-cntr {
  text-align: center; }

.alg-cntr button {
  width: 100%;
  border: 1px solid #999;
  padding: 8px;
  background: #f2f2f2;
  border-radius: 15px; }

#nav li:last-child {
  padding: 20px 0 0; }

.cart-item__remove {
  text-decoration: underline;
  font-size: 12px !Important;
  color: #2B638D; }

table.cart__table.cart__table--desktop.table {
  border-radius: 0px !Important; }

thead th:first-child {
  border-radius: 0px !important; }

thead th:last-child {
  border-radius: 0px !important; }

.cart__table th {
  font-weight: normal;
  font-size: 16px; }

.cart_all_total {
  border-bottom: 1px solid #4B4B4B;
  padding: 10px;
  margin-bottom: 15px; }

p.cart-item__variant {
  font-weight: normal;
  font-size: 12px;
  color: #939393; }

p.cart-item__variant:before {
  content: 'Color: ';
  text-transform: none; }
